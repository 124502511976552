import React, { useState } from 'react';
import Home from './components/Home';
import Modal from './components/Modal';

function App() {
	const [modalVisible, setModalVisible] = useState(false);
	const toggleModal = () => {
		if (modalVisible) {
			setModalVisible(false);
			document.body.classList.remove('stuck');
		} else {
			document.body.classList.add('stuck');
			setModalVisible(true);
		}
	};
	return (
		<div className="App">
			<Home toggleModal={toggleModal} />
			{modalVisible && <Modal toggleModal={toggleModal} />}
		</div>
	);
}

export default App;
