import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../images/logo.svg';
import Button from './Button';
import { HashLink } from 'react-router-hash-link';
import { useWindowScroll } from 'react-use';
import Modal from './Modal';
import { FormattedMessage } from 'react-intl';
import LanguageSelector from './LanguageSelector';

const StyledHeader = styled.header`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 100;
	background: ${props => (props.scrolled ? props.theme.redGradient : 'transparent')};
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: all 0.2s ease;
	padding: 0 0.8rem;
	min-height: 64px;
	width: 100%;

	@media (min-width: ${props => props.theme.breakpoints.small}) {
		padding: 0 2rem;
		min-height: ${props => (props.scrolled ? '72px' : '86px')};
	}

	.left-nav {
		display: inline-flex;
		align-items: center;

		.logo {
			height: auto;
			display: inline-block;

			.big {
				display: block;
				width: 86px;
				height: auto;

				@media (min-width: ${props => props.theme.breakpoints.small}) {
					display: block;
					margin-right: 3rem;
					width: 100px;
					height: auto;
				}
			}
		}

		ul {
			display: none;

			@media (min-width: ${props => props.theme.breakpoints.medium}) {
				display: inline-flex;
			}

			li {
				display: inline-flex;
				margin-left: 1rem;

				button,
				a {
					display: inline-flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-between;
					padding: 1rem;
					text-decoration: none;
					line-height: 1;

					&:hover {
						font-weight: bold;
					}

					&::after {
						content: attr(title) / '';
						height: 0;
						visibility: hidden;
						overflow: hidden;
						user-select: none;
						pointer-events: none;
						font-weight: bold;
					}
				}
			}
		}
	}

	.right-nav {
		display: inline-flex;
		align-items: center;

		ul {
			display: inline-flex;
			align-items: center;

			li {
				margin-left: 0.7rem;

				@media (min-width: ${props => props.theme.breakpoints.small}) {
					margin-left: 2rem;
				}

				button {
					padding: 0.8rem;
					white-space: nowrap;

					&.ghost,
					&.register {
						display: none;
						@media (min-width: ${props => props.theme.breakpoints.small}) {
							display: flex;
						}
					}

					@media (min-width: ${props => props.theme.breakpoints.small}) {
						padding: 14px 24px;

						&.icon-button {
							padding: 11px;
						}
					}
				}
			}
		}
	}

	.cashier-button {
		.cashier-icon {
			margin-left: 6px;
			font-size: 19px;
		}
	}
`;

function Header() {
	const [scrolled, setScrolled] = useState(false);
	const { y } = useWindowScroll();

	useEffect(() => {
		if (y !== 0) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	}, [y]);

	const [modalVisible, setModalVisible] = useState(false);
	const toggleModal = () => {
		if (modalVisible) {
			setModalVisible(false);
			document.body.classList.remove('stuck');
		} else {
			document.body.classList.add('stuck');
			setModalVisible(true);
		}
	};

	return (
		<StyledHeader scrolled={scrolled} id="header">
			<nav className="left-nav">
				<HashLink to="/#root" smooth className="logo">
					<Logo className="big" />
				</HashLink>
				<ul>
					<li>
						<HashLink smooth to="/#why">
							<FormattedMessage id="header.why" />
						</HashLink>
					</li>
					<li>
						<HashLink smooth to="/#commission">
							<FormattedMessage id="header.commission" />
						</HashLink>
					</li>
					<li>
						<HashLink smooth to="/#faq">
							<FormattedMessage id="header.faq" />
						</HashLink>
					</li>
				</ul>
			</nav>
			<nav className="right-nav">
				<ul>
					<li>
						<LanguageSelector />
					</li>
					<li>
						<a
							href="https://affiliates.magicjackpotaffiliates.com/affiliates"
							target="_blank"
							rel="noreferrer noopener"
						>
							<Button small ghost className="ghost">
								<FormattedMessage id="header.logIn" />
							</Button>
						</a>
					</li>
					<li className="small-margin register">
						<Button small black className="register" onClick={() => toggleModal()}>
							<FormattedMessage id="header.register" />
						</Button>
					</li>
				</ul>
			</nav>
			{modalVisible && <Modal toggleModal={toggleModal} />}
		</StyledHeader>
	);
}

export default Header;
