const theme = {
	red: 'rgba(180,40,46,1)',
	// red: '#ED1C24',
	fontBlack: '#36575D',
	overlayBlack: 'rgba(23, 48, 51, 0.9)',
	black: '#173033',
	blackHover: '#203b3f',
	mint: '#84D8C2',
	lightMint: '#bde6dd',
	gold: '#D9AA68',
	yellow: '#f5c052',
	white: '#FFF',
	offWhite: '#F2FAF8',
	borderRadius: '8px',
	redGradient:
		'linear-gradient(90deg, rgba(134,24,16,1) 0%, rgba(180,40,46,1) 50%, rgba(220,65,70,1) 100%)',
	boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)'
};

theme.breakpoints = {
	small: '767px',
	medium: '1080px'
};

export default theme;
