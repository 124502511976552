import * as React from 'react';

function HomeIcon(props) {
	return (
		<svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
			<path fill="none" d="M0 0h24v24H0z" />
			<path
				fill="currentColor"
				d="M13 19h6V9.978l-7-5.444-7 5.444V19h6v-6h2v6zm8 1a1 1 0 01-1 1H4a1 1 0 01-1-1V9.49a1 1 0 01.386-.79l8-6.222a1 1 0 011.228 0l8 6.222a1 1 0 01.386.79V20z"
			/>
		</svg>
	);
}

export default HomeIcon;
