import React, { useContext } from 'react';
import { use100vh } from 'react-div-100vh';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from './Modal';
import Button from './Button';
import { FormattedMessage } from 'react-intl';
import { Context } from './Wrapper';

const StyledHero = styled.section`
	background-color: ${props => props.theme.red};
	min-height: 600px;
	height: calc(${props => props.$height}px - 64px);
	width: 100%;
	position: relative;
	overflow: hidden;
	background-size: cover;
	background-position: 0 0;
	background-image: url('https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=1440,format=auto,dpr=2/assets/hero.png');

	@media (min-width: ${props => props.theme.breakpoints.small}) {
		height: 100vh;
		background-position: 50% 50%;
	}

	.background-image {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		pointer-events: none;
		object-position: 0 0;

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			object-position: 50% 50%;
		}
	}

	.container {
		display: grid;
		margin: 0 auto;
		height: 100%;
		padding: 1rem;
		justify-items: center;
		align-items: center;

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			padding: 2rem;
			grid-template-columns: 1fr 1fr;
			max-width: 1400px;
		}
	}

	.left {
		justify-content: flex-end;
		color: white;
		text-align: center;
		position: relative;

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			text-align: left;
		}

		h1 {
			color: inherit;
			font-size: 1.8rem;
			line-height: 1.2;
			margin-bottom: 1rem;

			@media (min-width: ${props => props.theme.breakpoints.small}) {
				font-size: 3rem;
				margin-bottom: 1.5rem;
			}
		}

		p {
			font-size: 20px;

			span {
				color: ${props => props.theme.yellow};
				font-weight: bold;
			}
		}

		.buttons {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 160px;
			align-items: center;
			margin-top: 38px;

			@media (min-width: ${props => props.theme.breakpoints.small}) {
				justify-content: space-between;
				max-width: 420px;
				height: 80px;
				flex-direction: row;
			}
			.button {
				height: 70px;
				align-items: center;
				&.primary {
					width: 210px;
				}
				&.ghost {
					width: 210px;
					font-size: 18px;
					@media (min-width: ${props => props.theme.breakpoints.small}) {
						margin-left: 24px;
						width: 216px;
					}
				}
			}
		}
	}
`;

function Hero({ toggleModal, modalVisible }) {
	const height = use100vh();
	const context = useContext(Context);
	const { locale } = context;

	return (
		<StyledHero $height={height}>
			<div className="container">
				<div className="left">
					<h1>
						<FormattedMessage id="hero.welcome" />
					</h1>
					<div className="buttons">
						<Button onClick={() => toggleModal()} primary black className="button primary">
							<FormattedMessage id="hero.buttonRegister" />
						</Button>
						<a
							className="contact-us"
							href="mailto:contact@magicjackpotaffiliates.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							<Button small ghost className={locale !== 'ro' ? 'button ghost' : 'button ghost ro'}>
								<FormattedMessage id="hero.buttonContact" />
							</Button>
						</a>
					</div>
				</div>
			</div>
			{modalVisible && <Modal toggleModal={toggleModal} />}
		</StyledHero>
	);
}

Hero.propTypes = {
	toggleModal: PropTypes.func.isRequired,
	modalVisible: PropTypes.func
};

export default Hero;
