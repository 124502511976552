import React, { useContext } from 'react';
import styled from 'styled-components';
import { Context } from './Wrapper';

import ukround from '../images/uk-round.png';
import roround from '../images/ro-round.png';

const StyledLanguageSelector = styled.div`
	display: contents;

	.menu-container {
		position: relative;
		width: 35px;
		z-index: 2;

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			width: 35px;
		}

		svg {
			width: 100%;
			height: 100%;
			pointer-events: none;
		}
		button {
			position: relative;
			padding: 0 !important;
		}
	}

	.menu {
		background: transparent;

		opacity: 0;
		visibility: hidden;
		transform: translateY(-20px);
		transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
	}

	.hidden {
		display: none;
	}

	.menu.active {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}

	.menu ul {
		list-style: none;
		padding: 0 !important;
		margin: 0 !important;
		display: flex !important;
	}

	.menu li {
		margin: 0 !important;
      display: flex;
		img {
			width: 35px;
			pointer-events: none;
		}

		button {
			height: 35px;
			border-radius: 50%;
			position: relative;
			padding: 0 !important;
		}
	}

	.menu-trigger {
		background: transparent;
		border-radius: 50%;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: none;
		vertical-align: middle;
		height: 35px;
	}
`;

const LanguageSelector = () => {
	const context = useContext(Context);
	const { locale } = context;
	const { selectLanguage } = context;

	return (
		<StyledLanguageSelector>
			<div className="menu active">
				<ul>
					{locale === 'ro' ? (
						<li>
							<button type="button" onClick={selectLanguage} value="en" className="en">
								<img src={ukround} alt="United Kingdom flag for language selection" />
							</button>
						</li>
					) : (
						<li>
							<button type="button" onClick={selectLanguage} value="ro" className="ro">
								<img src={roround} alt="Romanian flag for language selection" />
							</button>
						</li>
					)}
				</ul>
			</div>
		</StyledLanguageSelector>
	);
};

export default LanguageSelector;
