import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import Romanian from '../lang/ro.json';
import English from '../lang/en.json';

export const Context = React.createContext();

const Wrapper = props => {
	const [locale, setLocale] = useState('ro');
	const [messages, setMessages] = useState(Romanian);
	const { children } = props;

	function selectLanguage(e) {
		const newLocale = e.target.value;
		setLocale(newLocale);
		if (newLocale === 'en') {
			setMessages(English);
		} else if (newLocale === 'ro') {
			setMessages(Romanian);
		}
	}

	return (
		<Context.Provider value={{ locale, selectLanguage }}>
			<IntlProvider messages={messages} locale={locale}>
				{children}
			</IntlProvider>
		</Context.Provider>
	);
};

Wrapper.propTypes = {
	children: PropTypes.any.isRequired
};

export default Wrapper;
