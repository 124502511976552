import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import LazyLoad from 'react-lazyload';

const StyledSplash = styled.section`
	padding: 2rem 1rem;
	background: ${props => props.theme.offWhite};

	@media (min-width: ${props => props.theme.breakpoints.medium}) {
		padding: 100px 2rem;
	}

	h1 {
		margin-bottom: 0;
	}

	h2 {
		text-align: center;
		font-weight: bold;
		line-height: 1.2;
		font-size: 24px;
		margin-bottom: 24px;

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			margin-bottom: 4.5rem;
			font-size: 2rem;
			line-height: 1.3;
		}
	}

	.wrapper {
		text-align: center;
		display: grid;
		@media (min-width: ${props => props.theme.breakpoints.medium}) {
			margin-bottom: 0;
			grid-template-columns: 1fr 1fr 1fr;
		}
		.column {
			display: flex;
			flex-flow: column;
			align-items: center;
			margin-bottom: 2rem;

			h2 {
				margin-bottom: 16px;
				font-size: 20px;
				@media (min-width: ${props => props.theme.breakpoints.medium}) {
					font-size: 1.5rem;
					font-weight: bold;
					line-height: 1.2;
					margin-bottom: 1rem;
				}
			}

			img {
				max-width: 200px;
				margin: 0 auto 32px;
				width: 100%;
				height: auto;

				@media (min-width: ${props => props.theme.breakpoints.medium}) {
					max-width: 300px;
				}
			}

			p {
				text-align: center;
				font-size: 16px;
				line-height: 1.4rem;
				padding: 0 2em;

				@media (min-width: ${props => props.theme.breakpoints.medium}) {
					max-width: 350px;
				}
			}
		}
	}
`;

function Splash() {
	return (
		<StyledSplash id="why">
			<h2 className="header">
				<FormattedMessage id="info.whyMagicJackpot" />
			</h2>
			<div className="wrapper">
				<div className="column">
					<LazyLoad height={326} width={326} offset={400}>
						<img
							src="https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=326,height=326,format=auto,dpr=2/assets/diamonds.png"
							alt="Diamonds"
						/>
					</LazyLoad>
					<h2>
						<FormattedMessage id="info.headerFirst" />
					</h2>
					<p>
						<FormattedMessage id="info.textFirst" />
					</p>
				</div>
				<div className="column">
					<LazyLoad height={326} width={326} offset={400}>
						<img
							src="https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=326,height=326,format=auto,dpr=2/assets/chip.png"
							alt="Chip"
						/>
					</LazyLoad>
					<h2>
						<FormattedMessage id="info.headerSecond" />
					</h2>
					<p>
						<FormattedMessage id="info.textSecond" />
					</p>
				</div>
				<div className="column">
					<LazyLoad height={326} width={326} offset={400}>
						<img
							src="https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=326,height=326,format=auto,dpr=2/assets/star.png"
							alt="Star"
						/>
					</LazyLoad>
					<h2>
						<FormattedMessage id="info.headerThird" />
					</h2>
					<p>
						<FormattedMessage id="info.textThird" />
					</p>
				</div>
			</div>
		</StyledSplash>
	);
}

export default Splash;
