import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledButton = styled.button`
	display: inline-flex;
	padding: 1rem 2rem;
	border: none;
	background: ${props => props.theme.yellow};
	border-radius: ${props => props.theme.borderRadius};
	color: ${props => props.theme.white};
	font-weight: 600;
	font-family: inherit;
   cursor: pointer;
   transition: all .2s ease;
   justify-content: center;
   white-space: nowrap;

   &:hover:not(:disabled) {
      background: ${props => props.theme.green};
   }

   &:disabled {
      background: ${props => props.theme.greenDisabled};
      cursor: not-allowed;
   }

   ${props =>
			props.icon &&
			css`
				padding: 11px;
				font-size: 25px;

				&:hover {
					background: rgba(0, 0, 0, 0.2);
				}
			`}

	${props =>
		props.small &&
		css`
			padding: 14px 24px;
		`}

	${props =>
		props.primary &&
		css`
			padding: 1.4em 3em;
			font-size: 18px;
		`}

	${props =>
		props.ghost &&
		css`
			background: none;
			box-shadow: inset 0 0 0 2px ${props => props.theme.white};

			&:hover:not(:disabled) {
				background: white;
				color: red;
			}
		`}

	${props =>
		props.white &&
		css`
			background: ${props => props.theme.white};
			color: ${props => props.theme.green};

			&:hover:not(:disabled) {
				background: white;
			}
		`}

      ${props =>
				props.black &&
				css`
					background: ${props => props.theme.black};

					&:hover:not(:disabled) {
						background: ${props => props.theme.gold};
					}
				`};

      ${props =>
				props.gold &&
				css`
					background: ${props => props.theme.gold};

					&:hover:not(:disabled) {
						background: ${props => props.theme.black};
					}
				`};
`;

function Button({ children, ...props }) {
	return <StyledButton {...props}>{children}</StyledButton>;
}

Button.propTypes = {
	children: PropTypes.any.isRequired
};

export default Button;
