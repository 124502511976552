import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import agelimit from '../images/18.svg';
import chip from '../images/chip-pattern.svg';
import onjn from '../images/onjn.png';
import rombet from '../images/rombet.png';
import Clock from './Clock';
import LogoLandscape from './LogoLandscape';
import { FormattedMessage } from 'react-intl';

const StyledFooter = styled.footer`
	background-color: ${props => props.theme.black};
	color: white;
	position: relative;
	overflow: hidden;

	.pattern-wrapper-1 {
		position: relative;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: 100%;

		.pattern {
			background-image: url(${chip});
			background-size: 32px;
			background-repeat: repeat;
			height: 100%;
			width: 100%;
			z-index: 1;
			transform-origin: top right;
			transform: rotate(-60deg);
		}
	}

	.content {
		display: grid;
		max-width: 1440px;
		margin: 0 auto;
		padding: 4rem 1rem 6rem;
		grid-gap: 2rem;
		position: relative;

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			grid-template-columns: 1fr 1fr 1fr;
			padding: 4rem 2rem 6rem;
		}

		.navigation {
			li {
				margin-bottom: 1.5rem;

				a {
					svg {
						width: 160px;
						height: auto;
					}
				}
			}
		}

		.text {
			max-width: 640px;
			grid-column: span 2;
		}

		.logos {
			display: flex;
			margin-top: 2rem;

			.image {
				height: 32px;
				width: auto;
				margin-right: 24px;
			}
		}
	}
`;

function Footer() {
	return (
		<StyledFooter id="footer">
			<div className="pattern-wrapper-1">
				<div className="pattern" />
			</div>
			<div className="content">
				<nav className="navigation">
					<ul>
						<li>
							<Link exact="true" to="/" className="logo">
								<LogoLandscape />
							</Link>
						</li>
						<li>
							<NavLink exact to="/terms-and-conditions">
								<FormattedMessage id="footer.termsAndConditions" />
							</NavLink>
						</li>
						<FormattedMessage id="footer.contact" />{' '}
						<a className="contact-us" href="mailto:contact@magicjackpotaffiliates.com">
							contact@magicjackpotaffiliates.com
						</a>
						<li>
							<Clock />
						</li>
					</ul>
				</nav>
				<div className="text">
					<p>
						<FormattedMessage id="footer.text1" />
					</p>
					<p>
						<FormattedMessage id="footer.text2" />
					</p>
					<p>
						<FormattedMessage id="footer.text3" />
					</p>
					<div className="logos">
						<a href="http://onjn.gov.ro/" target="_blank" rel="noopener noreferrer">
							<img src={onjn} alt="ONJN" className="image" />
						</a>
						<Link to="/joc-responsabil">
							<img src={agelimit} alt="Age Limit 18+" className="image" />
						</Link>
						<a
							href="http://www.rombet.com/jocul-responsabil"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={rombet} alt="Rombet" className="image" />
						</a>
					</div>
				</div>
			</div>
		</StyledFooter>
	);
}

export default Footer;
