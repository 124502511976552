import React, { useContext } from 'react';
import styled from 'styled-components';
import { Context } from './Wrapper';

const StyledTermsAndConditions = styled.div`
	width: 100%;
	background-color: ${props => props.theme.offWhite};
	color: ${props => props.theme.fontBlack};
	background-size: content;
	background-position: center center;
	position: relative;

	@media (min-width: ${props => props.theme.breakpoints.small}) {
		padding-top: 4em;
	}

	font-weight: 700;
	overflow: hidden;

	h1,
	h2,
	h3 {
		color: ${props => props.theme.black};
	}

	.wrapper {
		padding: 114px 32px 62px;

		@media (min-width: ${props => props.theme.breakpoints.medium}) {
			padding: 5em;
		}

		.text {
			max-width: 720px;
			width: 100%;
			margin: 0 auto;
			font-weight: 600;
			font-size: 16px;
			opacity: 0.8;

			h3 {
				font-size: 32px;
				font-weight: bold;
				margin-bottom: 32px;
			}

			* {
				max-width: 100%;
			}

			h1 {
				font-size: 32px;
				margin-bottom: 32px;
				line-height: 1.3;
			}
			h2 {
				margin-bottom: 32px;
				line-height: 1.3;
			}

			.subheader {
				font-size: 16px;
				font-weight: bold;
				margin-bottom: 24px;

				@media (min-width: 720px) {
					font-size: 18px;
					margin-bottom: 32px;
				}

				span {
					margin: 0 8px;
				}
			}

			h3 {
				font-size: 24px;
				margin-bottom: 20px;
				line-height: 1.3;

				@media (min-width: 720px) {
					font-size: 32px;
				}
			}

			a {
				font-weight: bold;
				color: inherit;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.small-header {
			text-transform: uppercase;
			font-weight: bold;
			margin-top: 3em;
			margin-bottom: 16px;
			font-size: 14px;
			display: block;
			letter-spacing: 1px;
			line-height: 1.3;

			@media (min-width: 720px) {
				margin-top: 64px;
			}
		}

		ul {
			list-style: dotted;
			margin: 1em 0 2em;
			padding: 0 0 0 20px;

			li {
				line-height: 1.6em;
				margin: 0 0 1em;
				padding: 0 0 0 1em;
				position: relative;
			}
		}

		ol {
			list-style-type: none;
			counter-reset: item;
			margin: 1em 0 2em;
			padding: 0;

			> li {
				list-style: none;
				counter-increment: item;

				> ol {
					> li {
						display: block;
						line-height: 1.6;
						margin: 0 0 1em;
						padding: 0 0 0 2.4em;
						position: relative;

						@media (min-width: 720px) {
							padding: 0 0 0 3em;
						}

						&:before {
							position: absolute;
							top: 0;
							left: 0;
							content: counters(item, '.');
							font-weight: bold;
							color: ${props => props.theme.blue};
							font-size: 16px;

							@media (min-width: 720px) {
								font-size: 18px;
							}
						}

						> ol {
							list-style: none;
							padding: 0;
							margin: 1em 0 2em;
							counter-reset: item;

							> li {
								position: relative;
								line-height: 1.6;
								margin: 0 0 1em;
								padding: 0 0 0 2em;
								counter-increment: item;

								@media (min-width: 720px) {
									padding: 0 0 0 3em;
								}

								&:before {
									content: counter(item, lower-alpha) ')';
									position: absolute;
									top: 0;
									left: 0;
									font-weight: bold;
									font-size: 16px;

									@media (min-width: 720px) {
										font-size: 18px;
									}
								}

								> ol {
									list-style: none;
									padding: 0;
									margin: 1em 0 2em;
									counter-reset: item;

									> li {
										position: relative;
										line-height: 1.6;
										margin: 0 0 1em;
										padding: 0 0 0 2em;
										counter-increment: item;

										@media (min-width: 720px) {
											padding: 0 0 0 3em;
										}

										&:before {
											content: counter(item, lower-roman) '.';
											position: absolute;
											top: 0;
											left: 0;
											font-weight: bold;
											color: ${props => props.theme.blue};
											font-size: 16px;

											@media (min-width: 720px) {
												font-size: 18px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	small {
		font-size: 0.8em;
		line-height: 1.6;

		margin-bottom: 1em;
		display: block;
	}
`;

const TermsAndConditions = () => {
	const context = useContext(Context);
	const { locale } = context;

	return (
		<StyledTermsAndConditions>
			{locale === 'ro' ? (
				<div className="wrapper">
					<div className="text">
						<h1>Termeni și condiții</h1>
						<h2>Programul pentru afiliați MagicJackpot</h2>
						<p className="subheader">
							Versiunea 1.0<span>•</span> Ultima actualizare: Ianuarie 2021
						</p>
						<p>
							Programul pentru afiliați MagicJackpot (“Programul pentru Afiliați”) este furnizat de
							MJP Interactive LTD, (denumită în continuare “Compania”), o Companie înființată în
							conformitate cu legislația din Malta, cu sediul social la adresa LEVEL G (OFFICE
							1/2597), QUANTUM HOUSE 75, ABATE RIGORD STREET, TA’XBIEX XBX 1120, înregistrată la
							Registrul Comerțului din Malta cu nr. C 95830, C.I.F. 42830794. Drept de organizare în
							baza licenței L1203785W001253 cu valabilitate 01.01.2021-31.12.2030 și în baza
							autorizației de exploatare obținută prin decizia 2715/23.12.2020.
						</p>
						<p>
							Completând cererea de Afiliat pentru înscriere în Programul pentru Afiliați și făcând
							clic pe “Am citit și sunt de acord cu Termenii și Condițiile pentru Afiliați
							(“Termenii”)” din formularul de înregistrare, dvs. (denumit în continuare “Afiliat”)
							sunteți de acord să participați la Programul pentru Afiliați și să respectați toți
							Termenii și Condițiile din prezentul Contract. Structura comisioanelor prezentată în
							Articolul 20 al prezentului Contract va fi considerată parte integrală a acestuia.
							Compania își rezervă dreptul de a modifica, schimba, șterge sau extinde orice
							prevederi ale prezentului Contract, în orice moment și la absoluta sa discreție, fără
							a transmite o notificare prealabilă Afiliatului în conformitate cu Termenii prevăzuți
							în prezentul Contract.
						</p>
						<p>
							Veți fi obligat(ă) să respectați în mod continuu Termenii prezentului Contract,
							Termenii și Condițiile Generale și Politicile de Confidențialitate ale website-ului
							Magicjackpot.ro (denumit în continuare “Website-ul Companiei”), precum și orice alte
							reguli și/sau linii directoare elaborate în mod periodic. Un contract între Companie
							și Afiliat va intra în vigoare la data la care cererea Afiliatului este aprobată de
							Companie.
						</p>
						<ol>
							<li>
								<span className="small-header">ARTICOLUL 1</span>
								<h3>Scop</h3>
								<ol>
									<li>
										Compania se ocupă cu servicii de marketing, logistice și de suport generale în
										legătură cu jocurile de la distanță. „Companie licențiată de guvernul României,
										cu răspundere legală privind aspectele publicitare, de marketing și promoționale
										ale companiei de jocuri online desfășurate prin Site-urile Companiei și, ca
										parte a acestei funcții, compania operează Programul de Afiliați MagicJackpot.
									</li>
									<li>
										Afiliatul menține și operează unul sau mai multe website-uri pe internet
										(denumite în continuare în mod colectiv “Website-ul Afiliatului”) și/sau trimite
										clienți potențiali către Website-urile Companiei prin alte canale.
									</li>
									<li>
										Prezentul Contract guvernează Termenii și Condițiile referitoare la promovarea
										Website-urilor Companiei de către Afiliat, prin care Afiliatul va primi un
										comision definit în prezentul Contract în funcție de traficul trimis către
										website-urile Companiei și în conformitate cu Termenii din prezentul Contract.
									</li>
									<li>
										Definițiile termenului “Venituri Nete” pot fi găsite în Articolul 20 al
										Termenilor. În cazul introducerii unui alt produs sau grup de produse în viitor,
										Compania își rezervă dreptul de a utiliza o definiție individuală a termenului
										“Venituri Nete” pentru fiecare produs.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 2</span>
								<h3>Acceptarea unui Afiliat:</h3>
								<ol>
									<li>
										Compania va evalua formularul de cerere al Afiliatului depus prin prezenta și îl
										va informa pe solicitant în scris (e-mail) dacă i s-a aprobat sau nu calitatea
										de membru. Compania își rezervă dreptul de a refuza orice înregistrare la unica
										și absoluta sa discreție.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 3</span>
								<h3>Condiții de calificare:</h3>
								<ol>
									<li>
										Prin prezenta, Afiliatul garantează că:
										<ol>
											<li>
												are vârsta legală în jurisdicția aplicabilă pentru a-și exprima acordul și a
												încheia un contract.
											</li>
											<li>
												este competent și autorizat în mod adecvat să încheie contracte angajante.
											</li>
											<li>
												este proprietarul tuturor drepturilor, licențelor și permiselor pentru a
												comercializa, promova și face reclamă Website-urilor Companiei în
												conformitate cu prevederile prezentului Contract.
											</li>
											<li>
												va respecta toate regulile, legile și regulamentele aplicabile în legătură
												cu promovarea Website-urilor Companiei.
											</li>
											<li>înțelege și acceptă pe deplin Termenii Contractului.</li>
										</ol>
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 4</span>
								<h3>Responsabilitățile și Obligațiile Companiei:</h3>
								<ol>
									<li>
										Compania va furniza Afiliatului toate informațiile și materialele de marketing
										necesare pentru implementarea link-ului de urmărire.
									</li>
									<li>
										Afiliatul menține și operează unul sau mai multe website-uri pe internet
										(denumite în continuare în mod colectiv “Website-ul Afiliatului”) și/sau trimite
										clienți potențiali către Website-urile Companiei prin alte canale.
									</li>
									<li>
										Compania va administra cifra de afaceri generată prin link-urile de urmărire, va
										înregistra veniturile nete și suma totală a comisioanelor câștigate prin
										intermediul link-ului, va furniza Afiliatului statistici privind comisioanele și
										va gestiona toate serviciile de relații cu clienții referitoare la afacere. Un
										cod de identificare a urmăririi unic va fi alocat clienților trimiși.
									</li>
									<li>
										Compania va plăti Afiliatului suma datorată în funcție de traficul generat în
										conformitate cu Termenii prezentului Contract.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 5</span>
								<h3>Responsabilitățile și Obligațiile Afiliatului:</h3>
								<ol>
									<li>
										Prin prezenta, Afiliatul garantează:
										<ol>
											<li>
												să depună eforturi optime pentru a face reclamă, comercializa și promova în
												mod activ și eficace Website-urile Companiei cât mai mult posibil pentru a
												maximiza beneficiul părților și a respecta liniile directoare ale Companiei
												după cum sunt elaborate în mod periodic și/sau publicate online.
											</li>
											<li>
												să promoveze și trimită clienți potențiali către Website-urile Companiei pe
												costul și cheltuiala sa. Afiliatul va fi exclusiv responsabil pentru
												distribuirea, conținutul și maniera activităților sale de marketing. Toate
												activitățile de marketing ale Afiliatului trebuie să fie profesionale,
												adecvate și legale în conformitate cu legile aplicabile și trebuie să fie în
												conformitate cu prezentul Contract.
											</li>
											<li>
												să folosească doar un link de urmărire furnizat în cadrul obiectului
												Programului pentru Afiliați; în caz contrar nu se va oferi nicio garanție
												pentru înregistrarea adecvată și contabilizarea vânzărilor. De asemenea, să
												nu schimbe sau modifice în niciun fel niciun link sau material de marketing
												fără autorizarea scrisă prealabilă a Companiei.
											</li>
											<li>
												să fie responsabil pentru dezvoltarea, operarea și întreținerea website-ului
												său, precum și pentru toate materialele care apar pe website-ul său.
											</li>
										</ol>
									</li>
									<li>
										Prin prezenta, Afiliatul garantează:
										<ol>
											<li>
												că nu va desfășura nicio activitate defăimătoare, discriminatorie, obscenă,
												ilegală sau inadecvată sau care conține materiale explicite sexual,
												pornografice, obscene sau violente grafic.
											</li>
											<li>că nu va viza în mod activ niciun minor pentru jocuri de noroc.</li>
											<li>
												că nu va viza în mod activ nicio jurisdicție în care jocurile de noroc și
												promovarea acestora sunt ilegale.
											</li>
											<li>
												că nu va genera trafic pe Website-urile Companiei prin activități ilegale
												sau frauduloase, în special, fără limitare:
												<ol>
													<li>trimiterea de spam.</li>
													<li>meta etichetari incorecte..</li>
													<li>
														înregistrarea ca jucător sau efectuarea de depuneri în mod direct sau
														indirect în orice cont al unui jucător prin tracker-ul său
														(tracker-urile sale) în interes personal și/sau în beneficiul rudelor,
														prietenilor sau angajaților săi sau al unor alte terțe părți, sau orice
														altă încercare de a crește în mod artificial comisionul achitabil sau
														fraudarea Companiei. Încălcare acestei prevederi va fi considerată
														fraudă și va acorda Companiei dreptul de a rezilia Contractul cu
														valabilitate imediată, de a închide orice conturi asociate cu Afiliatul
														și să rețină orice câștiguri ale unui Afiliat care încalcă această
														prevedere.
													</li>
												</ol>
											</li>
											<li>
												că nu va prezenta Website-ul Afiliatului în niciun fel care să evoce vreun
												risc de confuzie cu MagicJackpot și/sau Website-urile Companiei și/sau
												Compania și/sau să dea impresia că Website-ul Afiliatului își are originea
												total sau parțial în MagicJackpot și/sau Companie.
											</li>
											<li>
												cu excepția materialelor de marketing transmise de Companie și/sau furnizate
												online prin website-ul 
												<a href="https://www.magicjackpotaffiliates.com">
													{' '}
													https://www.magicjackpotaffiliates.com{' '}
												</a>
												, Afiliatul nu poate folosi "MagicJackpot" sau alți termeni, mărci
												înregistrate și alte drepturi de proprietate intelectuală care aparțin
												Companiei sau MagicJackpot, cu excepția cazului în care Compania sau Magic
												Jackpot își exprimă acordul scris cu privire la respectiva utilizare.
											</li>
											<li>
												că nu va achiziționa, încerca să înregistreze sau înregistra cuvinte cheie,
												termeni de căutare sau alți identificatori pentru utilizare în orice motor
												de căutare, portal, serviciu de publicitate sponsorizat sau alt serviciu de
												căutare sau recomandare care sunt identice sau similare cu oricare dintre
												website-urile și mărcile înregistrate, denumirile comerciale ale Companiei
												sau care să includă cuvintele "MagicJackpot" sau variațiuni ale acestora.
											</li>
											<li>
												că nu va folosi niciuna dintre mărcile înregistrate sau denumirile
												comerciale ale Website-urilor Companiei și nu va include cuvintele “Magic
												Jackpot” sau variațiuni care sunt similare și pot crea confuzii, în
												URL-urile Afiliatului. Denumirile de marcă nu pot fi folosite într-un URL
												sau subdomeniu derivat.
											</li>
											<li>
												că va genera trafic pe Website-ul Companiei doar prin activități SEO și PPC.
												Traficul de pe e-mail, SMS, nativ sau orice alt tip de marketing direct nu
												sunt permise cu excepția cazului în care au fost aprobate în scris în
												prealabil de Echipa pentru Afiliați a MagicJackpot.
											</li>
											<li>
												că nu va folosi niciun personaj MagicJackpot în scopuri de marketing.
											</li>
											<li>
												se va asigura că toate activitățile de marketing sunt clare, promovate
												corect și menționează că se aplică T&C. Un simbol cu mesaj 18+ împreună cu
												un link către begambleaware.org sau o versiune locală vor fi incluse când se
												promovează MagicJackpot. Când se promovează un bonus MagicJackpot trebuie
												să se includă orice cerințe privind pariurile, suma maximă disponibilă a
												bonusului, depunerea minimă necesară pentru a activa bonusul, orice limite
												de retragere atașate bonusului și trebuie să se includă “doar pentru
												clienții noi” dacă se promovează un bonus pentru clienți noi. Termenii și
												Condițiile promoționale complete trebuie să fie disponibile printr-un singur
												clic.
											</li>
											<li>
												nu se va da impresia că jocurile de noroc pot fi o sursă de venit sau o
												modalitate de a plăti datorii.
											</li>
											<li>
												dacă induce daune în formă de amenzi sau penalități pentru Companie, va fi
												responsabil pentru aceste daune.
											</li>
											<li>
												nerespectarea acestor Termeni și Condiții va acorda Echipei pentru Afiliați
												MagicJackpot dreptul de a rezilia imediat prezentul Contract.
											</li>
										</ol>
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 6</span>
								<h3>Plata:</h3>
								<ol>
									<li>
										Compania este de acord să plătească Afiliatului un comision bazat de Veniturile
										Nete generate de la noii clienți trimiși de website-ul și/sau alt canal al
										Afiliatului. Clienții noi sunt acei clienți ai Companiei care nu au încă și nu
										au avut un cont de pariuri pe niciun Website al Companiei și care accesează
										Website-ul Afiliatului prin link-ul de urmărire și care se înregistrează în mod
										adecvat și fac transferuri de bani reali cel puțin echivalente cu depunerea
										minimă în contul lor MagicJackpot. Comisionul se va considera a include taxa pe
										valoarea adăugată sau orice altă taxă dacă este aplicabilă.
									</li>
									<li>
										Comisionul va fi un procent al Veniturilor Nete în conformitate cu prevederile
										din structurile comisioanelor pentru acel produs specific. Calcularea este
										specifică în funcție de produs și este stabilită în fiecare structură a
										comisionului specifică produsului.
									</li>
									<li>
										Comisionul este calculat la sfârșitul fiecărei luni și plățile vor fi efectuate
										până la sfârșitul următoarei luni calendaristice, cu condiția ca suma datorată
										să depășească 100€ pentru transferuri bancare și 100€ pentru Skrill și Neteller
										(prag minim). Dacă soldul datorat este mai mic decât pragul minim, va fi
										reportat în luna viitoare și va fi achitabil când soldul cumulat depășește
										pragul minim.
									</li>
									<li>
										Plata comisioanelor va fi efectuată în conformitate cu metoda de plată aleasă de
										către Afiliat în contul Afiliatului. Dacă se face o eroare în calcularea
										comisionului, Compania își rezervă dreptul de a corecta respectivul calcul în
										orice moment și va plăti imediat suma în minus sau va revendica suma în plus
										plătită către Afiliat.
									</li>
									<li>
										Acceptarea plății către Afiliat va fi considerată achitarea completă și finală a
										soldului datorat pentru perioada indicată.
									</li>
									<li>
										Dacă Afiliatul nu este de acord cu soldul datorat raportat, într-o perioadă de
										30 (treizeci) de zile va trimite un e-mail Companiei la adresa
										<a
											href="contact@magicjackpotaffiliates.com"
											target="_blank"
											rel="noopener noreferrer"
										>
											contact@magicjackpotaffiliates.com
										</a>{' '}
										și va indica motivele pentru respectiva dispută. Netrimiterea unui e-mail în
										perioada de timp prescrisă va fi considerată o acceptare irevocabilă a soldului
										datorat pentru perioada indicată.
									</li>
									<li>
										Compania poate amâna plata oricărui sold către Afiliat până la 180 (o sută
										optzeci) de zile, în timp ce investighează și verifică faptul că tranzacțiile
										relevante respectă prevederile Termenilor.
									</li>
									<li>
										Nu va fi datorată nicio plată când traficul generat este ilegal sau contravine
										oricărei prevederi a acestor termeni.
									</li>
									<li>
										Afiliatul este de acord să returneze toate comisioanele primite pe baza unor
										tranzacții frauduloase sau falsificate, plus plus toate costurile pentru dosare
										juridice sau acțiuni care pot fi inițiate împotriva Afiliatului în conformitate
										cu prevederile legale.
									</li>
									<li>
										Din motive de claritate, părțile convin în mod specific că odată cu rezilierea
										prezentului Contract de către oricare dintre părți, Afiliatul nu va mai avea
										dreptul de a primi nicio plată de la Companie, cu condiția ca plățile deja
										datorate (comisioanele obținute și neachitate) să fie efectuate.
									</li>
									<li>
										La unica discreție a Companiei, Afiliatul poate avea oportunitatea de a-și
										restructura structura comisioanelor. Exemple de surse de venituri alternative ar
										putea include un model de tip costuri per achiziții (CPA). După ce un Afiliat
										acceptă oferta Companiei pentru o structură de venituri diferită față de
										structura standard a comisioanelor indicată în articolul 20, Afiliatul este
										informat și prin prezenta își exprimă acordul și înțelegerea că noua structură
										de venituri propusă va înlocui integral structura sa de comisioane existentă.
										Independent de prevederile de mai sus, toate obligațiile Afiliatului asumate în
										cadrul prezentului Contract vor continua să se aplice Afiliatului până la
										rezilierea prezentului Contract și ulterior în conformitate cu Termenii și
										Condițiile conținute în prezentul Contract.
									</li>
									<li>
										Afiliatul va fi exclusiv responsabil pentru plata oricăror și tuturor taxelor,
										impozitelor, tarifelor, costurilor și oricăror alte sume achitabile sau datorate
										atât la nivel local cât și în alte țări (dacă este cazul) către orice autoritate
										fiscală, departament, sau altă entitate competentă de către Afiliat ca urmare a
										veniturilor generate în conformitate cu prevederile prezentului Contract.
										Compania nu va fi în nicio manieră considerată răspunzătoare pentru nicio sumă
										neplătită dar considerată a fi datorată de către Afiliat și prin prezenta
										Afiliatul compensează Compania în acest sens.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 7</span>
								<h3>Reziliere:</h3>
								<ol>
									<li>
										Prezentul Contract poate fi reziliat de oricare dintre părți printr-o notificare
										scrisă transmisă cu 30 (treizeci) de zile în avans celeilalte părți. Notificarea
										scrisă poate fi scrisă printr-un e-mail.
									</li>
									<li>
										Prin prezenta, părțile contractante convin că odată cu rezilierea prezentului
										Contract:
									</li>
									<li>
										Prin prezenta, părțile contractante convin că odată cu rezilierea prezentului
										Contract:
										<ol>
											<li>
												Afiliatul trebuie să șteargă toate referirile la MagicJackpot și/sau
												mărcile sale (inclusiv mărcile sucursalelor sale) și sau Website-urile
												Companiei de pe website-urile Afiliatului și/sau alte canale și comunicări
												de marketing, indiferent dacă acele comunicări sunt comerciale sau
												non-comerciale.
											</li>
											<li>
												toate drepturile și licențele acordate Afiliatului în cadrul prezentului
												Contract vor fi imediat reziliate și toate drepturile vor reveni
												licențiatorilor respectivi, iar Afiliatul va înceta să utilizeze orice mărci
												înregistrate, mărci de servicii, sigle și alte denumiri care aparțin
												Companiei.
											</li>
											<li>
												Afiliatul va avea dreptul doar asupra comisioanelor obținute și neplătite
												începând cu data efectivă a rezilierii; însă cu condiția reținerii de către
												Companie a plății finale a Afiliatului o perioadă e timp rezonabilă pentru a
												se asigura că este achitată suma corectă. Afiliatul nu va fi eligibil să
												obțină su să primească comisioane după această dată.
											</li>
											<li>
												Dacă prezentul Contract este reziliat de către Companie pe baza încălcării
												de către Afiliatul a termenilor sau legilor aplicabile, Compania va avea
												dreptul să rețină comisioanele Afiliatului obținute dar neachitate la data
												rezilierii drept garanție pentru orice revendicare care derivă din
												respectiva încălcare. În plus, se specifică faptul că rezilierea de către
												Companie din cauza unei încălcări a Afiliatului a oricăreia dintre clauzele
												Contractului nu va necesita un preaviz și respectiva reziliere va avea
												valabilitate imediată odată cu simpla notificare de către Companie către
												Afiliat.
											</li>
											<li>
												Afiliatul trebuie să returneze către Companie oricare și toate informațiile
												confidențiale (și toate copiile și materialele derivate ale acestora) care
												se află în posesia, custodia și controlul Afiliatului.
											</li>
											<li>
												Afiliatul va exonera Compania de toate obligațiile și răspunderile care apar
												sau derivă după data respectivei rezilieri, cu excepția acelor obligații
												care prin natura lor vor continua să fie valabile după reziliere. Rezilierea
												nu-l va exonera pe Afiliat de nicio răspundere care derivă din orice
												încălcare a prezentului Contract, care a avut loc înainte de reziliere
												și/sau orice răspundere care derivă din orice încălcare a informațiilor
												confidențiale chiar dacă încălcarea are loc în orice moment după rezilierea
												prezentului Contract. Obligația de confidențialitate a Afiliatului față de
												Companie va continua să fie valabilă după rezilierea prezentului Contract.
											</li>
											<li>
												Afiliatul trebuie să fie conștient că orice acțiune a sa care contravine
												prezentului Contract poate avea consecințe foarte grave pentru Afiliat,
												pentru MagicJackpot sau pentru ambele Părți, inclusiv, fără limitare,
												amenzi, penalități, încălcări ale condițiilor de licențiere și a capacității
												de a face afaceri, precum și potențiale acțiuni civile și penale împotriva
												Afiliatului sau MagicJackpot de către autoritățile respective. Fără a
												prejudicia niciunul dintre drepturile Companiei prevăzute în prezentul
												contract sau în legislație, Compania poate rezilia imediat prezentul
												Contract, parțial sau integral, sau oricare dintre conturile dvs. dacă
												încălcați prevederile menționate mai sus și Afiliatul va fi pe deplin
												responsabil și răspunzător pentru orice asemenea amendă, penalitate,
												revendicare, acțiune sau pierdere rezultată astfel, cauzată nouă ca urmare a
												acțiunilor sau încălcărilor dvs., după caz.
											</li>
										</ol>
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 8</span>
								<h3>Garanții:</h3>
								<ol>
									<li>
										Afiliatul confirmă și este de acord în mod expres că utilizează internetul pe
										riscul său și că acest Program pentru Afiliați este furnizat “așa cum este” și
										“așa cum este disponibil” fără niciun fel de garanții sau condiții, exprese sau
										implicite. Nu este oferită nicio garanție că va asigura accesul la website-ul
										său în niciun moment specific și la nicio locație specifică.
									</li>
									<li>
										În niciun caz Compania nu va fi răspunzătoare față de Afiliat sau nicio altă
										persoană pentru nicio inexactitate, eroare sau omisiune în, sau pierdere, rănire
										sau daună cauzată total sau parțial de erorile, întârzierile sau întreruperile
										Website-urilor Companiei sau Programului pentru Afiliați.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 9</span>
								<h3>Compensație:</h3>
								<ol>
									<li>
										Afiliatul este de acord să apere, compenseze și protejeze Compania și Afiliații,
										succesorii, directorii, angajații, agenții, administratorii, acționarii și
										avocaților săi de și împotriva oricăror și tuturor revendicărilor și
										răspunderilor, inclusiv onorariile rezonabile ale avocaților și experților,
										referitoare la sau care derivă din:
										<ol>
											<li>
												orice încălcare a declarațiilor, garanțiilor sau angajamentelor Afiliatului
												din prezentul Contract.
											</li>
											<li>
												utilizarea (sau utilizarea abuzivă) de către Afiliat a materialelor de
												marketing.
											</li>
											<li>
												toate acțiunile și activitățile care au loc sub numele de utilizator și
												parola Afiliatului.
											</li>
											<li>
												orice materiale defăimătoare, calomniatoare sau ilegale conținute în
												website-ul Afiliatului sau în informațiile și datele Afiliatului.
											</li>
											<li>
												orice revendicare sau acuzație precum că website-ul Afiliatului sau
												informațiile și datele Afiliatului încalcă orice patent, drept de autor,
												marcă înregistrată sau alte drepturi de proprietate intelectuală ale unor
												terțe părți sau încalcă drepturile oricărei terțe părți la confidențialitate
												sau publicitate.
											</li>
											<li>
												accesul sau utilizarea de către terțe părți a website-ului Afiliatului sau a
												informațiilor și datele Afiliatului.
											</li>
											<li>orice revendicare referitoare la Website-ul Afiliatului.</li>
											<li>orice încălcare a prezentului Contract.</li>
										</ol>
									</li>
									<li>
										Compania își rezervă dreptul de a participa, pe propria cheltuială, la apărarea
										oricărei chestiuni.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 10</span>
								<h3>Drepturile Companiei:</h3>
								<ol>
									<li>
										Compania și/sau MagicJackpot pot refuza orice jucător sau închide contul unui
										jucător dacă este necesar să se respecte politica Companiei and/or MagicJackpot
										și/sau să se protejeze interesele Companiei.
									</li>
									<li>
										Compania poate refuza orice solicitant și/sau poate închide orice cont al unui
										Afiliat dacă este necesar să se respecte politica Companiei and/or MagicJackpot
										și/sau să se protejeze interesele Companiei. Dacă Afiliatul încalcă prezentul
										Contract sau termenii Companiei sau alte reguli, politici și linii directoare
										ale Companiei, Compania poate, pe lângă închiderea contului Afiliatului, să ia
										orice alte măsuri conform legii pentru a-și proteja interesele.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 11</span>
								<h3>Legislația aplicabilă și jurisdicții:</h3>
								<ol>
									<li>
										Prezentul Contract va fi guvernat și interpretat în conformitate cu legislația
										din Malta și în ceea ce privește orice acțiune sau dispută referitoare la
										prezentul Contract Afiliatul acceptă în mod irevocabil jurisdicția tribunalelor
										din Malta.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 12</span>
								<h3>Cesionare:</h3>
								<ol>
									<li>
										Afiliatul nu poate afilia prezentul Contract, conform legii sau prin alte
										mijloace, fără a obține aprobarea scrisă prealabilă a Companiei. În cazul în
										care Afiliatul asigură sau obține controlul unui alt Afiliat al MagicJackpot,
										conturile vor coexista în termeni individuali.
									</li>
									<li>
										Compania poate cesiona prezentul Contract, conform legii sau prin alte mijloace,
										în orice moment, fără a obține aprobarea prealabilă a Afiliatului.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 13</span>
								<h3>Renunțare:</h3>
								<ol>
									<li>
										În cazul în care Compania nu obligă Afiliatul să respecte Termenii stipulați în
										prezentul Contract, această situație nu va constitui o renunțare la dreptul
										Companiei de a obliga Afiliatul să respecte respectivii Termeni în orice moment.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 14</span>
								<h3>Forță Majoră:</h3>
								<ol>
									<li>
										Nicio parte nu va fi răspunzătoare față de cealaltă parte pentru orice
										întârziere sau neîndeplinire a obligațiilor sale în conformitate cu prezentul
										Contract dacă respectiva întârziere sau neîndeplinire a obligațiilor derivă
										dintr-o cauză care nu este sub controlul rezonabil și care nu este din vina
										acelei părți, inclusiv, fără limitare, dispute de muncă, greve, perturbări
										industriale, calamități naturale, acțiuni de terorism, inundații, fulger,
										întreruperi ale utilităților sau comunicațiile, cutremure sau alte situații
										similare. Dacă intervine un eveniment de forță majoră, partea care nu și-a
										îndeplinit obligațiile este exonerată de îndeplinirea oricăror obligații care
										este împiedicată de evenimentul de forță majoră, în măsura în care îndeplinirea
										este împiedicată. Dacă evenimentul de forță majoră depășește 30 (treizeci) de
										zile, oricare dintre părți poate rezilia Contractul fără notificare.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 15</span>
								<h3>Relația Părților:</h3>
								<ol>
									<li>
										Nicio prevedere din prezentul Contract și nicio acțiune realizată de oricare
										parte a Contractului nu va fi considerată a constitui oricare parte (sau oricare
										angajați, agenți sau reprezentanți ai acelei părți) drept angajat sau
										reprezentant legal al celeilalte părți, sau a crea orice parteneriat, asociere
										în participațiune, asociere, sau sindicat între părți și nici a conferi oricărei
										părți orice drept, putere sau autoritate expresă sau implicită dea încheia orice
										contract sau angajament în numele (și nici de a impune orice obligație asupra)
										celeilalte părți.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 16</span>
								<h3>Invalidare Partiala: :</h3>
								<ol>
									<li>
										Oricând este posibil, fiecare prevedere a prezentului Contract va fi
										interpretată astfel încât să fie în vigoare și valabilă conform legislației
										aplicabile dar, dacă orice prevedere a prezentului Contract este considerată
										lipsită de valabilitate, ilegală sau inaplicabilă în orice privință, respectiva
										prevedere va fi lipsit de valabilitate doar în măsura lipsei de valabilitate,
										sau inaplicabilității sale, iar celelalte prevederi ale prezentului Contract vor
										continua să fie valabile. Nicio renunțare nu va fi dedusă dintr-o acțiune sau
										omisiune de a aplica orice drepturi și orice renunțare trebuie să fie exprimată
										în scris pentru a fi valabilă.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 17</span>
								<h3>Confidențialitate:</h3>
								<ol>
									<li>
										Toate informațiile, inclusiv, fără limitare, cele de afaceri și financiare,
										listele de clienți și cumpărători, precum și informațiile privind prețurile și
										vânzările și orice informații referitoare la produse, evidențe, operațiuni,
										planuri de afaceri, procese, informații despre produse, know-how sau logică de
										afaceri, secrete profesionale, oportunități de piață și date cu caracter
										personal ale Companiei, vor fi tratate drept confidențiale. Respectivele
										informații nu trebuie folosite în scopuri proprii comerciale sau alte scopuri
										sau divulgate oricărei persoane sau terțe părți, nici direct nici indirect,
										decât cu aprobarea scrisă prealabilă a Companiei; acestă prevedere va continua
										să fie valabilă după încetarea prezentului Contract.
									</li>
									<li>
										Afiliatul se angajează să nu utilizeze informațiile confidențiale în niciun alt
										scop decât îndeplinirea obligațiilor din prezentul Contract.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 18</span>
								<h3>Modificările prezentului Contract:</h3>
								<ol>
									<li>
										Compania își rezervă dreptul de a modifica, schimba, șterge sau extinde orice
										prevederi ale prezentului Contract, în orice moment și la absoluta sa discreție,
										fără a transmite o notificare prealabilă Afiliatului în conformitate cu Termenii
										prevăzuți în prezentul Contract. Orice asemenea modificări vor fi publicate la
										adresa{' '}
										<a href="https://www.magicjackpotaffiliates.com">
											https://www.magicjackpotaffiliates.com
										</a>
										.
									</li>
									<li>
										În cazul oricărei discrepanțe între semnificațiile oricăror versiuni traduse ale
										prezentului Contract, versiunea în limba engleză va avea întâietate.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 19</span>
								<h3>Mărci înregistrate:</h3>
								<ol>
									<li>
										Nicio prevedere din prezentul Contract nu va acorda Afiliatului niciun drept,
										titlu sau participație asupra mărcilor înregistrate, denumirilor comerciale,
										mărcilor pentru servicii sau altor drepturi de proprietate intelectuală
										[denumite în continuare drept „mărci”] ale Companiei sau MagicJackpot. În
										niciun moment în timpul sau după durata prezentului Contract Afiliatul nu va
										încerca sau ataca sau oferi asistență sau permite altora să atace sau să
										înregistreze sau să încerce să înregistreze mărcile Companiei sau MagicJackpot
										(inclusiv grupul său de societăți). Cu condiția ca acel Afiliat să nu
										înregistreze sau să nu încerce să înregistreze nicio marcă sau domeniu de
										website care este identic sau similar cu orice marcă ce aparține Companiei sau
										MagicJackpot (inclusiv grupul său de societăți).
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 20</span>
								<h3>Structura Comisioanelor:</h3>
								<p>Comisioanele sunt plătite ca procent din Veniturile Nete.</p>
								<p>
									Pentru Afiliații care s-au înscris după 1 ianuarie 2021, Veniturile Nete
									(aplicabile pentru toate produsele) sunt definite după cum urmează:
								</p>
								<p>
									Pariuri – Câștiguri –  Tarif de administrare* – Contribuția din Jackpot  – Bonusuri plătite** – Costuri aferente Tranzacțiilor & Rambursări – Impozite Aplicabile pentru Jocuri de Noroc***
								</p>
								<p>
									Procentul din Veniturile Nete este determinat de numărul noilor clienți care
									efectuează depuneri trimiși de Afiliat în luna în care se achită comisionul.
								</p>
								<p>numărul noilor clienți care efectuează depuneri – Procentul Afiliatului</p>
								<ul>
									<li>0-5 -25%</li>
									<li>5-10 -30%</li>
									<li>10-20 -35%</li>
									<li>20+ -40%</li>
								</ul>
								<p>
									Suma comisionului obținută când se ajunge la un nivel se va aplica tuturor
									clienților, de asemenea celor trimiși și care ajung la acel nivel.
								</p>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 21</span>
								<h3>Politica privind Clienții Valoroși:</h3>
								<ol>
									<li>
										În orice lună, dacă un Client generează venituri nete negative de cel puțin
										10.000€, va fi considerat, în scopul aplicării prezentei secțiuni, un „Client
										Valoros”.
									</li>
									<li>
										Dacă Veniturile Nete cumulate pentru Afiliat, în acea lună, pentru o Marcă
										Comercială sunt negative de minimum 2.000 €, se va aplica politica privind
										Clienții Valoroși cu următoarele prevederi:
									</li>
									<li>
										Veniturile Nete negative generate de către Clientul Valoros vor fi reportate și
										compensate pe baza Veniturilor Nete viitoare generate de acel Client Valoros;
									</li>
									<li>
										Soldul negativ reportat nu poate fi compensat pe baza altor Venituri Nete ale
										Clientului;
									</li>
									<li>
										Soldul negativ reportat nu poate fi mai mare decât Veniturile Nete negative
										cumulate totale pentru acea Marcă specifică din contul Afiliatului, pentru acea
										lună;
									</li>
									<li>
										Soldul negativ al unui Client Valoros va fi redus cu Veniturile Nete pozitive
										viitoare pe care le generează în lunile următoare;
									</li>
									<li>
										Un sold negativ nu va fi majorat cu Venituri Nete negative viitoare decât dacă
										acel Client Valoros îndeplinește criteriile de calificare în lunile ulterioare.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">ARTICOLUL 22</span>
								<h3>Prevederi diverse</h3>
								<ol>
									<li>
										Dacă există orice discrepanță între contractul privind afiliații în limba
										engleză și versiunea din orice altă limbă, contractul în limba engleză va avea
										întâietate.
									</li>
								</ol>
							</li>
						</ol>
						<small>
							* Tariful de administrare este o valoare care conține tariful de licențiere, tariful
							furnizorului de jocuri și tariful platformei
						</small>
						<small>
							** În acest caz, bonusurile înseamnă bonusuri plătite clientului, nu bonusuri
							acordate. Diferența este că un bonus plătit este un tip de sumă aferentă unui bonus
							pentru care clientul a îndeplinit cerința privind pariurile și/sau a primit fondurile
							în portofelul său de bani reali.
						</small>
						<small>
							*** Taxa pentru jocurile de noroc reprezintă sume plătite în forma unor obligații
							aferente pariurilor sau taxe achitabile de către operator către autorități, care nu
							includ impozitul pe veniturile corporative sau impozite echivalente. Impozitul pentru
							jocuri de noroc se aplică exclusiv veniturilor generate în jurisdicțiile relevante și
							va fi perceput la valoarea dată a impozitării de către autoritățile pentru jocuri de
							noroc respective.
						</small>
					</div>
				</div>
			) : (
				<div className="wrapper">
					<div className="text">
						<h1>Terms And Conditions</h1>
						<h2>MagicJackpot Affiliate Program</h2>
						<p className="subheader">
							Version 1.0 <span>•</span> Last updated: January 2021
						</p>
						<p>
							The Affiliate Program for MagicJackpot (the “Affiliate Program”) is provided by MJP
							Interactive LTD, (hereinafter “the Company”), a Company incorporated under the laws of
							Malta with it's office located at LEVEL G (OFFICE 1/2597), QUANTUM HOUSE 75, ABATE
							RIGORD STREET, TA’XBIEX XBX 1120 being registered with the Malta Business Registry
							under no. C 95830, C.I.F. 42830794. The right to operate is based on the license
							number: L1203785W001253, valid from 01.01.2021-31.12.2030 and based on the operating
							authorisation obtained by the decision number: 2715 / 23.12.2020.
						</p>
						<p>
							By completing the Affiliate application to the Affiliate Program and clicking “I have
							read and agree to the Affiliate Terms and Conditions (the “Terms”)” within the
							registration form, you (hereinafter the “Affiliate”) hereby agree to participate in
							the Affiliate Program and abide by all the Terms and Conditions set out in this
							Agreement. The outlined commission structure in Article 20 of this Agreement shall be
							deemed to form an integral part thereof. Company reserves the right to amend, alter,
							delete or extend any provisions of this Agreement, at any time and at its sole
							discretion, without giving any advance notice to the Affiliate subject to the Terms
							set out in this Agreement.
						</p>
						<p>
							You shall be obliged to continuously comply with the Terms of this Agreement, the
							general Terms and Conditions and Privacy Policies of the website Magicjackpot.ro
							(referred to as the “Company Website”), as well as with any other rules and/or
							guidelines brought forward from time to time. An agreement between the Company and the
							Affiliate shall be coming into effect on the date when the Affiliate application is
							approved by Company.
						</p>
						<ol>
							<li>
								<span className="small-header">Article 1</span>
								<h3>Purpose</h3>
								<ol>
									<li>
										The Company is in the business of marketing, logistical and general support
										services in connection with remote gaming. The Company licensed by the
										government of Romania to inter alia assume responsibility for the advertising,
										marketing and promotional aspects of the online gaming business carried out on
										Company Websites and as part of this function the Company is operating the Magic
										Jackpot Affiliates.
									</li>
									<li>
										The Affiliate maintains and operates one or more websites on the internet
										(hereinafter collectively referred to as “Affiliate Website”), and/or refers
										potential customers to Company Websites through other channels.
									</li>
									<li>
										This Agreement governs the Terms and Conditions which are related to the
										promotion of the Company Websites by the Affiliate, whereby the Affiliate will
										be paid a commission as defined in this Agreement depending on the traffic sent
										to the Company’s websites and as subject to the Terms in this Agreement.
									</li>
									<li>
										The definition of the term “Net Revenue” can be found within Article 20 of the
										Terms. In case of an introduction of another product, or group of products in
										the future, the Company reserves the right to use an individual definition of
										the term “Net Revenue” for each product.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 2</span>
								<h3>Acceptance of an Affiliate:</h3>
								<ol>
									<li>
										The Company shall evaluate the Affiliate application form hereby submitted and
										shall inform the applicant in writing (email) whether the membership is accepted
										or not. The Company reserves the right to refuse any registration in its sole
										and absolute discretion.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 3</span>
								<h3>Qualifying Conditions:</h3>
								<ol>
									<li>
										The Affiliate hereby warrants that he/she:
										<ol>
											<li>
												is of legal age in the applicable jurisdiction in order to agree to and to
												enter into an agreement.
											</li>
											<li>is competent and duly authorized to enter into binding agreements.</li>
											<li>
												is the proprietor of all rights, licenses and permits to market, promote and
												advertise Company Websites in accordance with the provisions of this
												Agreement.
											</li>
											<li>
												will comply with all applicable rules, laws and regulations in correlation
												with the promotion of Company Websites.
											</li>
											<li>fully understands and accepts the Terms of the Agreement.</li>
										</ol>
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 4</span>
								<h3>Responsibilities and Obligations of the Company:</h3>
								<ol>
									<li>
										The Company shall provide the Affiliate with all required information and
										marketing material for the implementation of the tracking link.
									</li>
									<li>
										The Affiliate maintains and operates one or more websites on the internet
										(hereinafter collectively referred to as “Affiliate Website”), and/or refers
										potential customers to Company Websites through other channels.
									</li>
									<li>
										The Company shall administrate the turnover generated via the tracking links,
										record the net revenues and the total amount of commission earned via the link,
										provide the Affiliate with commission statistics, and handle all customer
										services related to the business. A unique tracking identification code will be
										assigned to all referred customers.
									</li>
									<li>
										The Company shall pay the Affiliate the amount due depending on the traffic
										generated subject to the Terms of this Agreement.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 5</span>
								<h3>Responsibilities and Obligations of the Affiliate:</h3>
								<ol>
									<li>
										The Affiliate hereby warrants:
										<ol>
											<li>
												to use its best efforts to actively and effectively advertise, market and
												promote Company Websites as widely as possible in order to maximize the
												benefit to the parties and to abide by the guidelines of the Company as they
												may be brought forward from time to time and/or as being published online.
											</li>
											<li>
												to market and refer potential players to Company Websites at its own cost
												and expense. The Affiliate will be solely responsible for the distribution,
												content and manners of its marketing activities. All of the Affiliate’s
												marketing activities must be professional, proper and lawful under
												applicable laws and must be in accordance with this Agreement.
											</li>
											<li>
												to use only a tracking link provided within the scope of the Affiliate
												Program, otherwise no warranty whatsoever can be assumed for proper
												registration and sales accounting. Also, not to change or modify in any way
												any link or marketing material without prior written authorization from the
												Company.
											</li>
											<li>
												to be responsible for the development, the operation, and the maintenance of
												its website as well as for all material appearing on its website.
											</li>
										</ol>
									</li>
									<li>
										The Affiliate hereby warrants:
										<ol>
											<li>
												that it will not perform any act which is libellous, discriminatory,
												obscene, unlawful or otherwise unsuitable or which contains sexually
												explicit, pornographic, obscene or graphically violent materials.
											</li>
											<li>
												that it will not actively target any person who is under the legal age for
												gambling.
											</li>
											<li>
												that it will not actively target any jurisdiction where gambling and the
												promotion thereof is illegal.
											</li>
											<li>
												that it will not generate traffic to the Company Websites by illegal or
												fraudulent activity, particularly but not limited to by:
												<ol>
													<li>sending spam.</li>
													<li>incorrect meta tags.</li>
													<li>
														registering as a player or making deposits directly or indirectly to any
														player account through his tracker(s) for its own personal use and/or
														the use of its relatives, friends, employees or other third parties, or
														in any other way attempt to artificially increase the commission payable
														or to otherwise defraud the Company. Violation of this provision shall
														be deemed to be fraud and give the Company right to terminate the
														Agreement with immediate effect, to close any related Affiliate accounts
														and to retain any earnings of an Affiliate who is found to violate this
														point.
													</li>
												</ol>
											</li>
											<li>
												that it will not present Affiliate Website in any way that might evoke any
												risk of confusion with MagicJackpot and/or the Company Websites and/or the
												Company and/or convey the impression that the Affiliate Website is partly or
												fully originated from MagicJackpot and/or the Company.
											</li>
											<li>
												with exception of the marketing materials as may be forwarded by the Company
												and/or made available online through the website,{' '}
												<a href="https://www.magicjackpotaffiliates.com">
													{' '}
													https://www.magicjackpotaffiliates.com{' '}
												</a>
												, the Affiliate may not use "MagicJackpot" or other terms, trademarks and
												other intellectual property rights that are vested in the Company or Magic
												Jackpot unless the Company or MagicJackpot consents to such use in writing.
											</li>
											<li>
												that it will not purchase, attempt to register, or register keywords, search
												terms or other identifiers for use in any search engine, portal, sponsored
												advertising service or other search or referral service which are identical
												or similar to any of the Company’s websites and trademarks, trade names or
												otherwise include the word, "MagicJackpot" or variations thereof.
											</li>
											<li>
												that it will not use any of the Company Websites’ trademarks, trade names or
												otherwise include the word “MagicJackpot” or variations that are
												confusingly similar, in affiliate URLs. Brand names may not be used in a
												derivative URL or subdomain.
											</li>
											<li>
												that it will only generate traffic to the Company Website through SEO and
												PPC activities. Traffic originating from email, SMS, native or any other
												direct marketing is not allowed unless this has been approved beforehand in
												writing by the MagicJackpot Affiliates Team.
											</li>
											<li>
												that it will not use any of the MagicJackpot characters for marketing
												purposes.
											</li>
											<li>
												it will make sure that all marketing is clear, correctly advertised and
												mention that T&amp;C’s Apply. An 18+ message symbol together with a link to
												begambleaware.org or a local version will be included when promoting Magic
												Jackpot. When promoting a MagicJackpot bonus it must include any wagering
												requirements, maximum bonus amount available, the minimum deposit needed to
												activate the bonus, any withdrawal limits attached to the bonus and to
												include “new customers only” if it’s a welcome bonus that is being marketed.
												Full promotional Terms and Conditions need to be available one click away.
											</li>
											<li>
												it will not give the impression that gambling can be a source of income or a
												way to pay off debts.
											</li>
											<li>
												if it induces damages in form of fines or penalties for the Company. Then it
												will be held responsible for these damages.
											</li>
											<li>
												failure to follow these Terms and Conditions will grant the MagicJackpot
												Affiliates team the right to immediately terminate this Agreement.
											</li>
										</ol>
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 6</span>
								<h3>Payment:</h3>
								<ol>
									<li>
										The Company agrees to pay the Affiliate a commission based on the Net Revenue
										generated from new customers referred by the Affiliate’s website and/or other
										channel. New customers are those customers of the Company who do not yet have
										and have not had a betting account with any Company Website and who access the
										Affiliate Website via the tracking link and who properly register and make real
										money transfers at least equivalent to the minimum deposit into their Magic
										Jackpot account. The commission shall be deemed to be inclusive of value added
										tax or any other tax if applicable.
									</li>
									<li>
										The commission shall be a percentage of the Net Revenue in accordance with what
										is set out in the commission structures for the particular product. The
										calculation is product specific and it is set out in every product-specific
										commission structure.
									</li>
									<li>
										The commission is calculated at the end of each month and payments shall be
										performed by the end of the following calendar month, provided that the amount
										due exceeds €100 for Bank Wire transfers and €100 for Skrill and Neteller
										(minimum threshold). If the balance due is less than the minimum threshold, it
										shall be carried over to the following month and shall be payable when the
										accrued balance exceeds the minimum threshold.
									</li>
									<li>
										Payment of commissions shall be made as per the payment method chosen by the
										Affiliate in the Affiliate account. If an error is made in calculating the
										commission, the Company reserves the right to correct such calculation at any
										time and will immediately pay out underpayment or reclaim overpayment made to
										the Affiliate.
									</li>
									<li>
										Acceptance of payment by the Affiliate shall be deemed to be full and the final
										settlement of the balance due for the period indicated.
									</li>
									<li>
										If the Affiliate disagrees with the balance due as reported, it shall within a
										period of thirty (30) days send an email to the Company to
										<a
											href="contact@magicjackpotaffiliates.com"
											target="_blank"
											rel="noopener noreferrer"
										>
											contact@magicjackpotaffiliates.com
										</a>
										and indicate the reasons of such dispute. Failure to send an email within the
										prescribed time limit shall be deemed to be considered as an irrevocable
										acknowledgment of the balance due for the period indicated.
									</li>
									<li>
										The Company may delay payment of any balance to the Affiliate for up to one
										hundred and eighty (180) days, while it investigates and verifies that the
										relevant transactions comply with the provisions of the Terms.
									</li>
									<li>
										No payment shall be due when the traffic generated is illegal or contravenes any
										provision of these terms.
									</li>
									<li>
										The Affiliate agrees to return all commissions received based on fraudulent or
										falsified transactions, plus all costs for legal causes or actions that may be
										brought against the Affiliate to the fullest extent of the law.
									</li>
									<li>
										For the sake of clarity the parties specifically agree that upon termination of
										this Agreement by either party, the Affiliate shall no longer be entitled to
										receive any payment whatsoever from the Company, provided that payments already
										due (earned and unpaid commissions) shall be paid out.
									</li>
									<li>
										At the sole discretion of the Company the Affiliate may be given the opportunity
										to restructure its commission structure. Examples of alternative revenue sources
										could include a cost per acquisition (CPA) model. Once an Affiliate opts to
										accept the Company’s offer for a different revenue structure to the standard
										commission structure outlined in article 20, then the Affiliate is aware and
										hereby agrees and understands that the new proposed revenue structure shall
										replace his existing commission structure in its entirety. Notwithstanding the
										above, all the Affiliate’s obligations assumed under this present agreement will
										still continue to apply to the Affiliate up until the termination of this
										Agreement and thereafter in accordance with the Terms and Conditions contained
										in this Agreement.
									</li>
									<li>
										The Affiliate shall be exclusively responsible for the payment of any and all
										taxes, levies, fees, charges and any other money payable or due both locally and
										abroad (if any) to any tax authority, department or other competent entity by
										the Affiliate as a result of the revenue generated under this Agreement. The
										Company shall in no manner whatsoever be held liable for any amounts unpaid but
										found to be due by the Affiliate and the Affiliate hereby indemnifies the
										Company in that regard.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 7</span>
								<h3>Termination:</h3>
								<ol>
									<li>
										This Agreement may be terminated by either party by giving a thirty (30) day
										written notification to the other party. Written notification may be given by an
										email.
									</li>
									<li>
										The contracting parties hereby agree that upon the termination of this
										Agreement:
									</li>
									<li>
										The contracting parties hereby agree that upon the termination of this
										Agreement:
										<ol>
											<li>
												the Affiliate must remove all references to MagicJackpot and/or its brands
												(including brands of its subsidiary companies) and/or the Company Websites
												from the Affiliate’s websites and/or other marketing channel and
												communications, irrespectively of whether the communications are commercial
												or non-commercial.
											</li>
											<li>
												all rights and licenses granted to the Affiliate under this Agreement shall
												immediately terminate and all rights shall revert to the respective
												licensors, and the Affiliate will cease the use of any trademarks, service
												marks, logos and other designations vested in the Company.
											</li>
											<li>
												the Affiliate will be entitled only to those earned and unpaid commissions
												as of the effective date of termination; however provided, the Company may
												withhold the Affiliate’s final payment for a reasonable time to ensure that
												the correct amount is paid. The Affiliate will not be eligible to earn or
												receive commissions after this date. date.
											</li>
											<li>
												if this Agreement is terminated by the Company on the basis of the
												Affiliate’s breach of terms or applicable laws, the Company shall be
												entitled to withhold the Affiliate’s earned but unpaid commissions as of the
												termination date as collateral for any claim arising from such breach. It is
												further specified that termination by the Company due to a breach by the
												Affiliate of any of the clauses in this Agreement shall not require a notice
												period and such termination shall have immediate effect upon simple
												notification by the Company to the Affiliate.
											</li>
											<li>
												the Affiliate must return to the Company any and all confidential
												information (and all copies and derivations thereof) in the Affiliate’s
												possession, custody and control.
											</li>
											<li>
												the Affiliate will release the Company from all obligations and liabilities
												occurring or arising after the date of such termination, except with respect
												to those obligations that by their nature are designed to survive
												termination. Termination will not relieve the Affiliate from any liability
												arising from any breach of this Agreement, which occurred prior to
												termination and/or to any liability arising from any breach of confidential
												information even if the breach arises at any time following the termination
												of this Agreement. The Affiliate’s obligation of confidentiality towards the
												Company shall survive the termination of this Agreement.
											</li>
											<li>
												The Affiliate is to be aware that any operation from its end which runs
												counter to this Agreement may result in very serious consequences for the
												Affiliate itself, for MagicJackpot or for both Parties, including without
												limitation, fines, penalties, breaches of license conditions and ability to
												do business, as well as potential civil and criminal action against the
												Affiliate or MagicJackpot by the respective authorities. Without prejudice
												to any of the Company’s rights herein or at law, the Company may forthwith
												terminate this Agreement, in part or in its entirety, or any of your
												accounts should you act in breach of the foregoing and the Affiliate shall
												be held fully responsible and liable for any such resulting fine, penalty,
												claim, action, or loss which is caused to us as a result of your action or
												default as the case may be.
											</li>
										</ol>
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 8</span>
								<h3>Warranties:</h3>
								<ol>
									<li>
										The Affiliate expressly acknowledges and agrees that the use of the internet is
										at its risk and that this Affiliate Program is provided “as is” and “as
										available” without any warranties or conditions whatsoever, express or implied.
										No guarantee is made that it will make access to its website possible at any
										particular time or any particular location.
									</li>
									<li>
										The Company shall in no event be liable to the Affiliate or anyone else for any
										inaccuracy, error or omission in, or loss, injury or damage caused in whole or
										in part by failures, delays or interruptions of the Company Websites or the
										Affiliate Program.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 9</span>
								<h3>Indemnification:</h3>
								<ol>
									<li>
										The Affiliate agrees to defend, indemnify and hold the Company and its
										Affiliates, successors, officers, employees, agents, directors, shareholders and
										attorneys, free and harmless from and against any and all claims and
										liabilities, including reasonable attorneys’ and experts’ fees, related to or
										arising from:
										<ol>
											<li>
												any breach of the Affiliate’s representations, warranties or covenants under
												this Agreement.
											</li>
											<li>the Affiliate’s use (or misuse) of the marketing materials.</li>
											<li>
												all conduct and activities occurring under the Affiliate’s user id and
												password.
											</li>
											<li>
												any defamatory, libellous or illegal material contained within the
												Affiliate’s website or as part of the Affiliate’s information and data.
											</li>
											<li>
												any claim or contention that the Affiliate’s website or the Affiliate’s
												information and data infringes any third party’s patent, copyright,
												trademark, or other intellectual property rights or violates any third
												party’s rights of privacy or publicity.
											</li>
											<li>
												third party access or use of the Affiliate’s website or to the Affiliate’s
												information and data.
											</li>
											<li>any claim related to the Affiliate Website.</li>
											<li>any violation of this Agreement.</li>
										</ol>
									</li>
									<li>
										The Company reserves the right to participate, at its own expense in the defense
										of any matter.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 10</span>
								<h3>Company Rights:</h3>
								<ol>
									<li>
										The Company, and/or MagicJackpot may refuse any player or close a player’s
										account if it is necessary to comply with the Company’s and/or MagicJackpot’s
										policy and/or protect the interest of the Company.
									</li>
									<li>
										The Company may refuse any applicant and/or may close any Affiliate’s account if
										it is necessary to comply with the Company’s policy and/or protect the interest
										of the Company. If the Affiliate is in breach of this Agreement or the Company’s
										terms or other rules, policies and guidelines of the Company, the Company may
										besides closing the Affiliate’s account take any other steps at law to protect
										its interest.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 11</span>
								<h3>Governing Law &amp; Jurisdictions:</h3>
								<ol>
									<li>
										This Agreement shall be governed and construed in accordance with the laws of
										Malta and any action or dispute relating to this Agreement must be brought in
										the Affiliate irrevocably consents to the jurisdiction of the Maltese courts.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 12</span>
								<h3>Assignment:</h3>
								<ol>
									<li>
										The Affiliate may not assign this Agreement, by operation of law or otherwise,
										without obtaining the prior written consent of the Company. In the event that
										the Affiliate acquires or otherwise obtains control of another Affiliate of
										MagicJackpot, then accounts will coexist on individual terms.
									</li>
									<li>
										The Company may assign this Agreement, by operation of the law or otherwise, at
										any time without obtaining the prior consent of the Affiliate.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 13</span>
								<h3>Waiver:</h3>
								<ol>
									<li>
										The Company’s failure to enforce the Affiliate’s adherence to the Terms outlined
										in this Agreement shall not constitute a waiver of the right of the Company to
										enforce said terms at any time.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 14</span>
								<h3>Force Majeure:</h3>
								<ol>
									<li>
										Neither party shall be liable to the other for any delay or failure to perform
										its obligations under this Agreement if such delay or failure arises from a
										cause beyond the reasonable control of and is not the fault of such party,
										including but not limited to labor disputes, strikes, industrial disturbances,
										acts of god, acts of terrorism, floods, lightning, utility or communications
										failures, earthquakes or other casualty. If a force majeure event occurs, the
										non-performing party is excused from whatever performance is prevented by the
										force majeure event to the extent prevented. Provided that, if the force majeure
										event subsists for a period exceeding thirty (30) days then either party may
										terminate the Agreement without notice.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 15</span>
								<h3>Relationship of the Parties:</h3>
								<ol>
									<li>
										Nothing contained in this Agreement, nor any action taken by any party to this
										Agreement, shall be deemed to constitute either party (or any of such party’s
										employees, agents, or representatives) an employee, or legal representative of
										the other party, nor to create any partnership, joint venture, association, or
										syndication among or between the parties, nor to confer on either party any
										express or implied right, power or authority to enter into any agreement or
										commitment on behalf of (nor to impose any obligation upon) the other party.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 16</span>
								<h3>Severability</h3>
								<ol>
									<li>
										Whenever possible, each provision of this Agreement shall be interpreted in such
										a manner as to be effective and valid under applicable law but, if any provision
										of this Agreement is held to be invalid, illegal or unenforceable in any
										respect, such provision will be ineffective only to the extent of such
										invalidity, or unenforceability, without invalidating the remainder of this
										Agreement. No waiver will be implied from conduct or failure to enforce any
										rights and must be in writing to be effective.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 17</span>
								<h3>Confidentiality:</h3>
								<ol>
									<li>
										All information, including but not limited to business and financial, lists of
										customers and buyers, as well as price and sales information and any information
										relating to products, records, operations, business plans, processes, product
										information, business know-how or logic, trade secrets, market opportunities and
										personal data of the Company shall be treated confidentially. Such information
										must not be used for own commercial or other purposes or divulged to any person
										or third party neither direct nor indirectly unless the prior explicit and
										written consent of the Company this provision shall survive the termination of
										this Agreement.
									</li>
									<li>
										The Affiliate obliges himself not to use the confidential information for any
										purpose other than the performance of its obligations under this Agreement.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 18</span>
								<h3>Changes to this Agreement:</h3>
								<ol>
									<li>
										The Company reserves the right to amend, alter, delete or add to any of the
										provisions of this Agreement, at any time and at its sole discretion, without
										giving any advance notice to the Affiliate subject to the Terms set out in this
										Agreement. Any such changes will be published on
										<a href="https://www.magicjackpotaffiliates.com">
											https://www.magicjackpotaffiliates.com
										</a>
										.
									</li>
									<li>
										In case of any discrepancy between the meanings of any translated versions of
										this Agreement, the english language version shall prevail.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 19</span>
								<h3>Trademarks:</h3>
								<ol>
									<li>
										Nothing contained in this Agreement will grant the Affiliate any right, title or
										interest in the trademarks, trade names, service marks or other intellectual
										property rights [hereinafter referred to simply as ‘marks’] of the Company or
										MagicJackpot. At no time during or after the term will Affiliate attempt or
										challenge or assist or allow others to challenge or to register or to attempt to
										register the marks of Company or MagicJackpot (including its group companies).
										Provided also that Affiliate shall not register nor attempt to register any mark
										or website domain which is identical or similar to any mark which belongs to
										Company or MagicJackpot (including its group companies).
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 20</span>
								<h3>Commission Structure:</h3>
								<p>Commissions are paid out as a percentage of the Net Revenue.</p>
								<p>
									For Affiliates which signed up after the 1st January 2021, the Net Revenue
									(applying to all products) is defined as:
								</p>
								<p>
									Bets – Wins – Admin Fee* – Jackpot Contribution – Paid Bonuses** – Transaction Costs & Chargebacks – Applicable Gambling Taxes***
								</p>
								<p>
									The Net Revenue share percentage is determined by the amount of new depositing
									customers referred by the Affiliate within the month the commission is paid for.
								</p>
								<p>Number of new depositing customers – Affiliate share</p>
								<ul>
									<li>0-5 -25%</li>
									<li>5-10 -30%</li>
									<li>10-20 -35%</li>
									<li>20+ -40%</li>
								</ul>
								<p>
									The amount of commission earned when reaching a tier will apply to all customers,
									also those referred to reach that tier.
								</p>
							</li>
							<li>
								<span className="small-header">Article 21</span>
								<h3>High Roller Policy:</h3>
								<ol>
									<li>
										In any given month, if a Customer generates a negative net revenue of at least
										€10,000 he/she will be deemed to be, for the purposes of this section, a
										‘High-Roller’.
									</li>
									<li>
										If the aggregate Net Revenue for the Affiliate, in that month, for a Merchant
										Brand is negative €2,000 or greater, then the High-Roller policy as set out
										hereunder, will apply:
									</li>
									<li>
										The negative Net Revenue generated by the High-Roller will be carried forward
										and offset against future Net Revenue generated by that High-Roller;
									</li>
									<li>
										The negative balance carried forward cannot be set-off against other Customers’
										Net Revenue;
									</li>
									<li>
										The negative balance carried forward cannot be greater than the total aggregate
										negative Net Revenue for that particular Brand on the Affiliate account, for
										that month;
									</li>
									<li>
										The negative balance of a High-Roller will be reduced by future positive Net
										Revenue that they generate in subsequent months;
									</li>
									<li>
										A negative balance will not be increased by future negative Net Revenue unless
										the High-Roller meets the qualifying criteria in subsequent months.
									</li>
								</ol>
							</li>
							<li>
								<span className="small-header">Article 22</span>
								<h3>Miscellaneous</h3>
								<ol>
									<li>
										If there would be any discrepancy between the affiliate agreement in English and
										any other language, the agreement in English shall prevail.
									</li>
								</ol>
							</li>
						</ol>
						<small>
							* The admin fee is a value which contains the license fee, game provider fee and
							platform fee
						</small>
						<small>
							** Bonuses in this case means paid bonuses to the customer, not granted bonuses. The
							difference is that a paid bonus is a type of bonus money where the customer has
							actually reached the wagering requirement and/or has received the funds on his/her
							real money wallet.
						</small>
						<small>
							*** The gambling tax is monies paid in the form of betting duties or taxes payable by
							the operator to the authorities, not including corporate income tax or equivalent. The
							gambling tax does solely apply to revenues being generated from within the relevant
							jurisdictions and will be charged at the given rate of taxation of the respective
							gambling authorities.
						</small>
					</div>
				</div>
			)}
		</StyledTermsAndConditions>
	);
};
export default TermsAndConditions;
