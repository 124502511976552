import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledAccordion = styled.div`
	margin-bottom: 16px;

	.accordion {
		color: ${props => props.theme.white};
		cursor: pointer;
		padding: 32px;
		width: 100%;
		text-align: left;
		border: none;
		outline: none;
		transition: 0.2s;
		display: flex;
		justify-content: space-between;
		border-radius: 24px;
		white-space: normal;
		line-height: 1.6;
		background: ${props => props.theme.fontBlack};

		&:after {
			content: '+';
			right: 1px;
		}

		span {
			margin: auto;
			pointer-events: none;
		}
	}

	.panel {
		color: ${props => props.theme.white};
		background: ${props => props.theme.overlayBlack};
		max-height: 0;
		overflow: hidden;
		transition: 0.2s;
		border-radius: 24px;
		max-width: calc(100% - 24px);
		margin: 0 auto;

		.panel-content {
			padding: 32px;
		}
	}

	${props =>
		props.active &&
		css`
			margin-bottom: 32px;

			.accordion {
				background: ${props => props.theme.overlayBlack};
				border-radius: 24px 24px 0 0;

				&:after {
					content: '-';
				}
			}

			.panel {
				display: block;
				max-width: 100%;
				border-radius: 0 0 24px 24px;
			}
		`};
`;

function Accordion({ question, answer }) {
	const [active, setActive] = useState(false);

	function handleClick(e) {
		const panel = e.target.nextElementSibling;
		setActive(!active);

		if (panel.style.maxHeight) {
			panel.style.maxHeight = null;
		} else {
			panel.style.maxHeight = `${panel.scrollHeight}px`;
		}
	}

	return (
		<StyledAccordion active={active}>
			<button type="button" className="accordion" onClick={e => handleClick(e)}>
				<span>{question}</span>
			</button>
			<div className="panel">
				<div className="panel-content">
					<p>{answer}</p>
				</div>
			</div>
		</StyledAccordion>
	);
}

Accordion.propTypes = {
	question: PropTypes.any.isRequired,
	answer: PropTypes.any.isRequired
};

export default Accordion;
