import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import App from './App';
import GlobalStyles from './styles/GlobalStyles';
import theme from './styles/theme';
import TermsAndConditions from './components/TermsAndConditions';
import Header from './components/Header';
import Wrapper from './components/Wrapper';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import MobileNav from './components/MobileNav';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<Wrapper>
					<Header />
					<Switch>
						<Route exact path="/" component={App} />
						<Route path="/terms-and-conditions" component={TermsAndConditions} />
						<Redirect to="/" />
					</Switch>
					<Footer />
               <MobileNav />
				</Wrapper>
				<ScrollToTop />
				<GlobalStyles />
			</ThemeProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);
