import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-use';
import styled from 'styled-components/macro';
import HomeIcon from './HomeIcon';
import LoginIcon from '../images/LoginIcon';
import { FormattedMessage } from 'react-intl';
import Register from '../images/register';
import Modal from './Modal';

const StyledMobileNav = styled.nav`
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	max-width: 100%;
	z-index: 101;
	display: flex;
	background: ${props => props.theme.white};
	padding-bottom: env(safe-area-inset-bottom);
	box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.2);

	@media (min-width: ${props => props.theme.breakpoints.small}) {
		display: none;
	}

	a,
	button {
		display: inline-flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		width: 25%;
		background: ${props => props.theme.white};
		margin: 0;
		padding: 0;
		width: 100%;
		height: 64px;
		position: relative;
		font-size: 11px;

		svg {
			height: 20px;
			width: 20px;
			margin-bottom: 4px;

			&.close {
				width: 16px;
				height: 16px;
			}
		}

		&.active {
			color: ${props => props.theme.red};
		}

		.badge {
			position: absolute;
			top: 14px;
			right: 25%;
			height: 21px;
			width: 21px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 21px;
			font-size: 10px;
			text-align: center;
			border: 3px solid ${props => props.theme.white};
			color: white;
			background: ${props => props.theme.red};
		}
	}
`;

function MobileNav() {
	const { pathname } = useLocation();

	function scrollHandler(url) {
		if (pathname === url) {
			window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		}
	}

	const [modalVisible, setModalVisible] = useState(false);
	const toggleModal = () => {
		if (modalVisible) {
			setModalVisible(false);
			document.body.classList.remove('stuck');
		} else {
			document.body.classList.add('stuck');
			setModalVisible(true);
		}
	};

	return (
		<StyledMobileNav id="mobile-nav">
			<NavLink exact to="/" onClick={() => scrollHandler(`/`)}>
				<HomeIcon />
				<FormattedMessage id="header.home" />
			</NavLink>
			<button type="button" className="cashier" onClick={() => toggleModal()}>
				<Register />
				<FormattedMessage id="header.register" />
			</button>
			<a
				href="https://affiliates.magicjackpotaffiliates.com/affiliates"
				target="_blank"
				rel="noreferrer noopener"
			>
				<button type="button" className="cashier" onClick={() => toggleModal()}>
					<LoginIcon />
					<FormattedMessage id="header.logIn" />
				</button>
			</a>
			{modalVisible && <Modal toggleModal={toggleModal} />}
		</StyledMobileNav>
	);
}

export default MobileNav;
