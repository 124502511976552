import React, { useState } from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import { ReactComponent as Spades } from '../images/spades-small.svg';
import Button from './Button';
import { FormattedMessage } from 'react-intl';

import Modal from './Modal';

const StyledCommission = styled.section`
	padding: 2rem 1rem;

	@media (min-width: ${props => props.theme.breakpoints.small}) {
		padding: 100px 2rem;
	}

	.container {
		display: grid;
		grid-gap: 2rem;
		max-width: 1400px;
		margin: 0 auto;

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.card {
		display: flex;
		border-radius: 16px;
		background: #bde6dd;
		position: relative;
		padding: 2rem;
		text-align: center;

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			padding: 4rem 3rem;
			text-align: left;
		}

		button {
			margin: 0;
		}

		h2 {
			font-weight: bold;
			line-height: 1.2;
			margin-bottom: 1rem;
			font-size: 24px;
			text-align: center;
			@media (min-width: ${props => props.theme.breakpoints.small}) {
				text-align: left;
				font-size: 2rem;
				margin-bottom: 1.5rem;
				line-height: 1.3;
			}
		}

		.suite {
			display: flex;
			flex-flow: column;
			text-align: center;
			font-size: 14px;
			font-weight: bold;
			color: ${props => props.theme.black};
			position: absolute;

			&.top {
				top: -30px;
				left: -15px;

				@media (min-width: ${props => props.theme.breakpoints.small}) {
					top: 16px;
					left: 16px;
				}
			}

			&.bottom {
				bottom: -30px;
				right: -15px;
				transform: rotate(180deg);

				span {
					top: 43%;
				}

				@media (min-width: ${props => props.theme.breakpoints.small}) {
					bottom: 16px;
					right: 16px;
				}
			}

			svg {
				width: 2rem;
				height: auto;
				margin-top: 7px;
			}

			span {
				position: absolute;
				top: 48%;
				left: 48%;
				color: ${props => props.theme.white};
				z-index: 1;
				transform: translate(-50%, -50%);
				font-size: 14px;
			}
		}

		&.big {
			@media (min-width: ${props => props.theme.breakpoints.small}) {
				grid-column-start: span 3;
				padding: 3rem;
			}

			.image {
				flex: 1;
				margin-top: -80px;
				padding-right: 32px;
				display: none;

				@media (min-width: ${props => props.theme.breakpoints.small}) {
					display: block;
				}

				img {
					width: 100%;
					height: auto;
				}
			}

			.text {
				flex: 2;

				@media (min-width: ${props => props.theme.breakpoints.small}) {
					margin-left: 2rem;
				}

				p {
					margin-bottom: 2rem;

					br {
						display: none;

						@media (min-width: ${props => props.theme.breakpoints.small}) {
							display: block;
						}
					}
				}

				h2 {
					font-size: 1.5rem;
					font-weight: bold;
					line-height: 1.2;
					margin-bottom: 1rem;

					@media (min-width: ${props => props.theme.breakpoints.small}) {
						font-size: 2rem;
						margin-bottom: 1.5rem;
						line-height: 1.3;
					}
				}
			}
		}

		.terms-link {
			display: block;
			margin-top: 2rem;
			text-decoration: underline;
		}
	}
`;

function Commission() {
	const [modalVisible, setModalVisible] = useState(false);
	const toggleModal = () => {
		if (modalVisible) {
			setModalVisible(false);
			document.body.classList.remove('stuck');
		} else {
			document.body.classList.add('stuck');
			setModalVisible(true);
		}
	};
	return (
		<StyledCommission id="commission">
			<div className="flex-wrapper">
				<div className="row">
					<div className="column">
						<div className="card big">
							<div className="suite top">
								A
								<Spades />
							</div>
							<div className="suite bottom">
								A
								<Spades />
							</div>
							<div className="image">
								<LazyLoad height={320} width={320} offset={400}>
									<img
										src="https://images.magicjackpot.ro/cdn-cgi/image/fit=cover,width=320,height=320,format=auto,dpr=2/assets/spades-and-chips.png"
										alt="Spades and chips"
										className="spades"
									/>
								</LazyLoad>
							</div>
							<div className="text">
								<h2>
									<FormattedMessage id="commission.header" />
								</h2>
								<p>
									<FormattedMessage id="commission.text" />
								</p>
								<Button onClick={() => toggleModal()} primary black className="button">
									<FormattedMessage id="commission.registerButton" />
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{modalVisible && <Modal toggleModal={toggleModal} />}
		</StyledCommission>
	);
}

export default Commission;
