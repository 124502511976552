/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Heart } from '../images/heart-small.svg';

const StyledTestimonial = styled.li``;

const Testimonial = ({ quote, quoter, link = '' }) => {
	return (
		<>
			<StyledTestimonial>
				<blockquote className="blockquote">
					<div className="card">
						<div className="suite top">
							<Heart />
						</div>
						<div className="suite bottom">
							<Heart />
						</div>
						<div className="text">
							<div>
								<p>{quote}</p>
								{link !== '' ? (
									<a href={link} target="_blank" className="quoter">
										{quoter}
									</a>
								) : (
									<span className="quoter no-link">{quoter}</span>
								)}
								<p></p>
							</div>
						</div>
					</div>
				</blockquote>
			</StyledTestimonial>
		</>
	);
};

export default Testimonial;
