import React, { useState } from 'react';
import Splash from './Splash';
import Testimonials from './Testimonials';
import Hero from './Hero';
import Faq from './Faq';
import Commission from './Commission';

import Modal from './Modal';

function Home() {
	const [modalVisible, setModalVisible] = useState(false);
	const toggleModal = () => {
		if (modalVisible) {
			setModalVisible(false);
			document.body.classList.remove('stuck');
		} else {
			document.body.classList.add('stuck');
			setModalVisible(true);
		}
	};

	return (
		<>
			<Hero toggleModal={toggleModal} />
			<Splash />
			<Commission />
			<Testimonials />
			<Faq />
			{modalVisible && <Modal toggleModal={toggleModal} />}
		</>
	);
}

export default Home;
