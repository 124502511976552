import React, { useEffect, useRef, useState } from 'react';
import { useScroll } from 'react-use';
import smoothscroll from 'smoothscroll-polyfill';
import styled from 'styled-components';
import { ReactComponent as Left } from '../images/arrow-left.svg';
import { ReactComponent as Right } from '../images/arrow-right.svg';
import Testimonial from './Testimonial';
import { FormattedMessage } from 'react-intl';
import testimonialsData from '../testimonials/data.json';

const StyledTestimonials = styled.section`
	text-align: center;
	padding: 2rem 0;
	background: ${props => props.theme.offWhite};
	@media (min-width: ${props => props.theme.breakpoints.small}) {
		padding: 100px 0;
	}
   .header-wrapper {
      position: relative;
      pointer-events: none;
      width: 100%;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
         position: absolute;
      }

   .header {
            margin: 0 auto;
         h2 {
            font-weight: bold;
            line-height: 1.2;
            margin-bottom: 1rem;
            font-size: 24px;

            @media (min-width: ${props => props.theme.breakpoints.small}) {
            font-size: 2rem;
            margin-bottom: 1.5rem;
            line-height: 1.3;
            }
         }

         p {
            font-size: 16px;
            padding: 10px;
         }
      }
   }
	.scroll-buttons {
		display: none;
		flex-direction: row;
		justify-content: flex-end;
		margin: 50px;
		font-size: 30px;
		/* color: ${props => props.theme.purple}; */

      @media (min-width: ${props => props.theme.breakpoints.small}) {
         display: flex;
      }

		&.hide {
			display: none !important;
		}

		.button-left-opacity {
			opacity: 0.6;
		}

		.button-right-opacity {
			opacity: 0.6;
		}
	}

	.container {
		display: grid;
		grid-gap: 2rem;
		max-width: 1400px;
		margin: 0 auto;

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.wrapper {
		width: 100%;
		display: flex;
		overflow-y: auto;

		scroll-behavior: smooth;
		/* Hide scrollbar for IE, Edge and Firefox */
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		/* Hide scrollbar for Chrome, Safari and Opera */

		&::-webkit-scrollbar {
			display: none;
		}

      .testimonials {

      }
	}

		.shadow {
			position: absolute;
			bottom: 0px;
			width: 50%;
			height: 30%;
			max-width: 200px;
			max-height: 50px;
			transform-origin: 0 100%;
			display: none;

			@media (min-width: ${props => props.theme.breakpoints.small}) {
				display: block;
			}

			&.left {
				left: 120px;
				box-shadow: -80px -30px 40px rgba(0, 0, 0, 0.4);
				transform: skew(50deg);
			}

			&.right {
				right: 120px;
				box-shadow: 80px -30px 40px rgba(0, 0, 0, 0.4);
				transform: skew(-50deg);
			}
		}

	.card {
   box-shadow: 211px 119px 46px -177px rgb(0 0 0 / 83%), -179px 120px 38px -175px rgb(0 0 0 / 58%);

      border: 1px solid rgba(0, 0, 0, 0.02);
		display: flex;
		border-radius: 16px;
		background: ${props => props.theme.white};
		position: relative;
		padding: 2rem;
		margin: 24px;
		min-width: 332px;
		height: 424px;
		align-items: center;

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			min-width: 400px;
			height: 400px;
		}

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			padding: 4rem 3rem;
			width: 400px;
		}

		.suite {
    display: flex;
    flex-flow: column;
    text-align: center;
    font-weight: 700;
    color: #173033;
    position: absolute;

			&.top {
				top: -16px;
				left: -16px;


				@media (min-width: ${props => props.theme.breakpoints.small}) {
					top: 16px;
					left: 16px;
				}
			}

			&.bottom {
				bottom: -16px;
				right: -16px;
				transform: rotate(180deg);

				span {
					top: 43%;
				}

				@media (min-width: ${props => props.theme.breakpoints.small}) {
					bottom: 16px;
					right: 16px;
				}
			}

			svg {
				width: 1rem;
				height: auto;
			}


		}

		&.big {
			@media (min-width: ${props => props.theme.breakpoints.small}) {
				grid-column-start: span 3;
				padding: 3rem;
			}

			.image {
				flex: 1;
				margin-top: -80px;
				padding-right: 32px;
				display: none;

				@media (min-width: ${props => props.theme.breakpoints.small}) {
					display: block;
				}

				svg {
					width: 100%;
					height: auto;
				}
			}

			.text {
				flex: 2;

				@media (min-width: ${props => props.theme.breakpoints.small}) {
					margin-left: 2rem;
				}

				p {
               font-size: 20px;

					br {
						display: none;

						@media (min-width: ${props => props.theme.breakpoints.small}) {
							display: block;
						}
					}
				}


			}
		}

		.quoter {
			display: block;
			margin-top: 2rem;
			text-decoration: underline;
		}
	}

`;

function Testimonials() {
	const [show, setShow] = useState(true);
	const scrollRef = useRef(0);
	const { x } = useScroll(scrollRef);
	const [scrollPosition, setscrollPosition] = useState(0);

	const maxWidth = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
	useEffect(() => {
		smoothscroll.polyfill();
		setscrollPosition(x);
	}, [x]);

	const clickHandlerLeft = () => {
		if (x !== 0) {
			document.getElementById('wrapper').scrollBy({ left: -896, behavior: 'smooth' });
		}
	};

	const clickHandlerRight = () => {
		smoothscroll.polyfill();
		document.getElementById('wrapper').scrollBy({ left: +896, behavior: 'smooth' });
	};

	// Look for touch. If "touch", remove scroll buttons.
	window.addEventListener('touchstart', function userTouch() {
		setShow(false);
	});

	return (
		<StyledTestimonials>
			<div className="header-wrapper">
				<div className="header">
					<h2>
						<FormattedMessage id="testimonials.header" />
					</h2>
					<p>
						<FormattedMessage id="testimonials.text" />
					</p>
				</div>
			</div>
			{show && (
				<>
					<div className="scroll-buttons" id="scroll-buttons">
						<button
							className={scrollPosition === 0 ? 'button-left-opacity' : 'button-left'}
							type="button"
							onMouseDown={() => clickHandlerLeft()}
						>
							<Left className="logo" />
						</button>
						<button
							className={
								scrollPosition === maxWidth && scrollPosition !== 0
									? 'button-right-opacity'
									: 'button-right'
							}
							type="button"
							onMouseDown={() => clickHandlerRight()}
						>
							<Right />
						</button>
					</div>
				</>
			)}
			<div className="testimonials">
				<ul className="wrapper" id="wrapper" ref={scrollRef}>
					{testimonialsData.testimonials.map(item => (
						<Testimonial
							quote={item.quote}
							quoter={item.quoter}
							link={item.link}
							key={item.quoter}
						/>
					))}
				</ul>
			</div>
		</StyledTestimonials>
	);
}

export default Testimonials;
