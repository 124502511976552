import React from 'react';
import styled from 'styled-components';

import Accordion from './Accordion';
import { FormattedMessage } from 'react-intl'


const StyledFaq = styled.section`
	padding: 3rem 0 1rem;
	background: ${props => props.theme.white};

	@media (min-width: ${props => props.theme.breakpoints.medium}) {
		padding: 100px 0;
	}
	h2 {
		color: ${props => props.theme.black};
		font-size: 32px;
		font-weight: bold;
		line-height: 1.3;
		margin-bottom: 24px;
	}

	.wrapper {
		max-width: 340px;
		margin: 0 auto;
		width: 100%;
		position: relative;
		text-align: center;

		@media (min-width: ${props => props.theme.breakpoints.medium}) {
			max-width: 900px;
		}

		h1 {
			font-size: 32px;
			margin-bottom: 32px;
		}

		p {
			margin-bottom: 32px;
			font-size: 14px;
		}

		@media (min-width: ${props => props.theme.breakpoints.medium}) {
			p {
				font-size: 16px;
			}
		}

		p,
		li {
			line-height: 1.6;
		}

		ul {
			li {
				line-height: 1.5;
				margin-bottom: 16px;
			}
		}

		.card {
			margin-top: 64px;
			margin-bottom: 32px;
			padding: 64px 32px;
			background: ${props => props.theme.darkBlue};
			border-radius: 32px;

			h2 {
				margin-top: 0;
			}
		}
	}
`;

function Faq() {
	return (
		<StyledFaq id="faq">
			<div className="wrapper">
				<div className="text">
					<h2>
						<FormattedMessage id="faq.header" />
					</h2>
					<p>
						<FormattedMessage id="faq.subHeader" /> <a href="mailto:contact@magicjackpotaffiliates.com">contact@magicjackpotaffiliates.com</a>
					</p>
				</div>
				<Accordion
					question={<FormattedMessage id="faq.q1" />}
					answer={<FormattedMessage id="faq.a1" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q2" />}
					answer={<FormattedMessage id="faq.a2" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q3" />}
					answer={<FormattedMessage id="faq.a3" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q4" />}
					answer={<FormattedMessage id="faq.a4" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q5" />}
					answer={<FormattedMessage id="faq.a5" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q6" />}
					answer={<FormattedMessage id="faq.a6" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q7" />}
					answer={<FormattedMessage id="faq.a7" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q8" />}
					answer={<FormattedMessage id="faq.a8" />}
				/>
				<Accordion
					question={<FormattedMessage id="faq.q9" />}
					answer={<FormattedMessage id="faq.a9" />}
				/>
			</div>
		</StyledFaq>
	);
}

export default Faq;
